import React, { useState, useMemo, useCallback } from "react";
import Footer from "../../components/_main/Footer";
import Header from "../../components/_main/Header";
import Tabs from "../../components/Tabs/Tabs";
import SidesMenu from "../SidesMenu";
import debounce from "lodash.debounce";

const Sides = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedQuery, setDebounceSearch] = useState("");

    const debouncedSearch = useCallback(
        debounce((value) => setDebounceSearch(value), 500),
        []
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        debouncedSearch(e.target.value);
    };

    return (
        <>
            <Header />
            <div className="nav-margin"></div>
            <Tabs />
            <div className="container">
                <div className="d-flex justify-content-between">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb custom-breadcrumb mt-5">
                            <li className="breadcrumb-item" aria-current="page">Home</li>
                            <li className="breadcrumb-item active" aria-current="page">Sides (Subs, Poutines)</li>
                        </ol>
                    </nav>
                    <div className="side-search-container mt-5 rounded-5 overflow-hidden d-none">
                        <input
                            type="text"
                            placeholder="Search Sides"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className=""
                        />
                    </div>
                </div>
            </div>
            <SidesMenu searchQuery={debouncedQuery} />
            <Footer />
        </>
    );
};

export default Sides;