import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import GlobalContext from "../../../context/GlobalContext";
import { v4 as uuidv4 } from "uuid";
import CartFunction from "../../cart";

function OtherModel({
    show,
    handleClose,
    data,
    ing,
    toppingsData,
    pizzaSize,
    pizzaQuantity
}) {
    const globalctx = useContext(GlobalContext);
    const [cart, setCart] = globalctx.cart;
    const [settings, setSettings] = globalctx.settings;
    const [currentStoreCode, setCurrentStoreCode] = globalctx.currentStoreCode;
    const [showStorePopup, setShowStorePopup] = globalctx.showStorePopup;
    const cartFn = new CartFunction();
    const user = useSelector((state) => state?.user);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceCode, setPriceCode] = useState(null);
    const [crust, setCrust] = useState(null);
    const [crustype, setCrustType] = useState(null);
    const [cheese, setCheese] = useState(null);
    const [specialBase, setspecialBase] = useState(null);
    const [spicy, setSpicy] = useState(null);
    const [sauce, setSauce] = useState(null);
    const [cook, setCook] = useState(null);

    useEffect(() => {
        if (data) {
            setPriceCode(pizzaSize)
            setCrust(data?.crust.code)
            setCrustType(data?.crust_type.code)
            setCheese(data?.cheese.code)
            setspecialBase(data?.special_base.code)
            setSpicy(data?.spices.code)
            setSauce(data?.sauce.code)
            setCook(data?.cook.code)
        }
    }, [pizzaSize])

    const CalculatePrice = () => {
        let totalPrice = 0;
        if (priceCode) {
            const pizzaPrice = data?.pizza_prices?.filter(el => el?.shortcode === priceCode);
            totalPrice = +totalPrice + Number(pizzaPrice[0]?.price)
        }
        if (crust) {
            const crustPrice = ing?.crust?.filter(el => el?.crustCode === crust);
            totalPrice = +totalPrice + Number(crustPrice[0]?.price)
        }
        if (crustype) {
            const crustTypePrice = ing?.crustType?.filter(el => el?.crustTypeCode === crustype);
            totalPrice = +totalPrice + Number(crustTypePrice[0]?.price)
        }
        if (cheese) {
            const cheesePrice = ing?.cheese?.filter(el => el?.cheeseCode === cheese);
            totalPrice = +totalPrice + Number(cheesePrice[0]?.price)
        }
        if (specialBase) {
            const specialBasePrice = ing?.specialbases?.filter(el => el?.specialbaseCode === specialBase);
            totalPrice = +totalPrice + Number(specialBasePrice[0]?.price)
        }
        if (spicy) {
            const spicyPrice = ing?.spices?.filter(el => el?.spicyCode === spicy);
            totalPrice = +totalPrice + Number(spicyPrice[0]?.price)
        }
        if (sauce) {
            const saucePrice = ing?.sauce?.filter(el => el?.sauceCode === sauce);
            totalPrice = +totalPrice + Number(saucePrice[0]?.price)
        }
        if (cook) {
            const cookPrice = ing?.cook?.filter(el => el?.cookCode === cook);
            totalPrice = +totalPrice + Number(cookPrice[0]?.price)
        }
        let totalP = totalPrice.toFixed(2) * Number(pizzaQuantity);
        setTotalPrice(Number(totalP).toFixed(2));
    }

    useEffect(() => {
        if (ing && pizzaSize) {
            CalculatePrice();
        }
    }, [priceCode, pizzaSize, crust, crustype, cheese, specialBase, spicy, sauce, cook, ing, pizzaQuantity])

    const handleAddToCart = () => {
        if (!currentStoreCode) {
            handleClose();
            setShowStorePopup(true)
            return false;
        }

        let pizza_size = data?.pizza_prices?.filter((el) => el.shortcode === priceCode);
        let pizzastate = [];

        let crustData = ing?.crust?.filter(el => el?.crustCode === crust);
        let crustObject = {
            crustCode: crustData[0]?.crustCode,
            crustName: crustData[0]?.crustName,
            price: crustData[0]?.price,
        };

        let crustTypeData = ing?.crustType?.filter(el => el?.crustTypeCode === crustype);
        let crustTypeObj = {
            crustTypeCode: crustTypeData[0]?.crustTypeCode,
            crustType: crustTypeData[0]?.crustType,
            price: crustTypeData[0]?.price,
        };

        let cheeseTypeData = ing?.cheese?.filter(el => el?.cheeseCode === cheese);
        let cheeseObject = {
            cheeseCode: cheeseTypeData[0]?.cheeseCode,
            cheeseName: cheeseTypeData[0]?.cheeseName,
            price: cheeseTypeData[0]?.price,
        };


        let spicyTypeData = ing?.spices?.filter(el => el?.spicyCode === spicy);
        let spicyObject = {
            spicyCode: spicyTypeData[0]?.spicyCode,
            spicy: spicyTypeData[0]?.spicy,
            price: spicyTypeData[0]?.price,
        };

        let sauceTypeData = ing?.sauce?.filter(el => el?.sauceCode === sauce);
        let sauceObject = {
            sauceCode: sauceTypeData[0]?.sauceCode,
            sauce: sauceTypeData[0]?.sauce,
            price: sauceTypeData[0]?.price,
        };

        let cookTypeData = ing?.cook?.filter(el => el?.cookCode === cook);
        let cookObject = {
            cookCode: cookTypeData[0]?.cookCode,
            cook: cookTypeData[0]?.cook,
            price: cookTypeData[0]?.price,
        };

        let specialBasesData = ing?.specialbases?.filter(el => el?.specialbaseCode === specialBase);
        let specialBasesObject = {
            specialbaseCode: specialBasesData[0]?.specialbaseCode,
            specialbaseName: specialBasesData[0]?.specialbaseName,
            price: specialBasesData[0]?.price,
        };


        let toppingTwoCode = data?.topping_as_2?.map((el) => el?.code);
        let toppingsTwoData = toppingsData?.toppings?.countAsTwo?.filter(el => toppingTwoCode?.includes(el?.toppingsCode));
        let toppingTwoArray = toppingsTwoData?.map((el) => {
            let matchedToppingTwo = data?.topping_as_2?.find(topping => topping?.code === el?.toppingsCode);
            return {
                toppingsCode: el?.toppingsCode,
                toppingsName: el?.toppingsName,
                toppingsPrice: matchedToppingTwo?.price || 0, // Use matched price or fallback to 0
                toppingsPlacement: "whole",
                amount: matchedToppingTwo?.price || 0,
                pizzaIndex: 0,
            };
        });


        let toppingOneCode = data?.topping_as_1?.map((el) => el?.code);
        let toppingsOneData = toppingsData?.toppings?.countAsOne?.filter(el => toppingOneCode?.includes(el?.toppingsCode));
        let toppingOneArray = toppingsOneData?.map((el) => {
            let matchedToppingOne = data?.topping_as_2?.find(topping => topping?.code === el?.toppingsCode)
            return {
                toppingsCode: el?.toppingsCode,
                toppingsName: el?.toppingsName,
                toppingsPrice: matchedToppingOne?.price || 0,
                toppingsPlacement: "whole",
                amount: matchedToppingOne?.price || 0,
                pizzaIndex: 0,
            }
        })

        let toppingFreeCode = data?.topping_as_free?.map((el) => el?.code);
        let toppingsFreeData = toppingsData?.toppings?.freeToppings?.filter(el => toppingFreeCode?.includes(el?.toppingsCode));
        let toppingFreeArray = toppingsFreeData?.map((el) => {
            let matchedToppingFree = data?.topping_as_2?.find(topping => topping?.code === el?.toppingsCode)
            return {
                toppingsCode: el?.toppingsCode,
                toppingsName: el?.toppingsName,
                toppingsPrice: matchedToppingFree?.price || 0,
                toppingsPlacement: "whole",
                amount: matchedToppingFree?.price || 0,
                pizzaIndex: 0,
            }
        })

        let allIndiansToppings = false;
        if (toppingsData?.toppings?.freeToppings?.length === toppingFreeArray?.length) {
            allIndiansToppings = true
        }

        let payload = {
            crust: crustObject,
            cheese: cheeseObject,
            crustType: crustTypeObj,
            specialBases: specialBasesObject,
            spicy: spicyObject,
            sauce: sauceObject,
            cook: cookObject,
            toppings: {
                countAsTwoToppings: toppingTwoArray || [],
                countAsOneToppings: toppingOneArray || [],
                freeToppings: toppingFreeArray || [],
                isAllIndiansTps: allIndiansToppings,
            },
        }
        pizzastate.push(payload)
        const obj = {
            id: uuidv4(),
            customerCode: user?.data?.customerCode,
            cashierCode: "#NA",
            productCode: data.code,
            productName: data.pizza_name,
            productType: "other_pizza",
            config: {
                pizza: pizzastate,
                sides: [],
                dips: [],
                drinks: [],
            },
            price: totalPrice,
            quantity: pizzaQuantity,
            amount: totalPrice,
            taxPer: 0,
            pizzaSize: pizza_size[0]?.size,
            pizzaPrice: pizza_size[0]?.price,
            comments: "",
        };

        if (obj) {
            let ct = JSON.parse(localStorage.getItem("cart"));
            ct.product.push(obj);
            const cartProduct = ct.product;
            cartFn.addCart(cartProduct, setCart, false, settings);
        }
        handleClose();
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header className="primary-background-color primary-text-color">
                <Modal.Title>
                    <p>{data.pizza_name}  (${totalPrice})</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="primary-background-color primary-text-color" style={{ maxHeight: "350px", overflow: "auto" }}>
                <Container className="overflow-y-auto max-w card-background-color card-text-color px-3 py-3">
                    <Row>
                        <Col xs={12} >
                            <label className="mb-4">
                                Quantity :
                                <span className="mx-2">
                                    <strong>
                                        {pizzaQuantity}
                                    </strong>
                                </span>
                            </label>
                        </Col>
                        <Col xs={12}>
                            <label className="mb-2">Select Size:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={priceCode}
                                onChange={(e) => setPriceCode(e.target.value)}
                            >
                                {data?.pizza_prices
                                    ?.filter(price => parseFloat(price.price) > 0) // Exclude zero prices
                                    .map(price => {
                                        return (
                                            <option value={price.shortcode} key={price.shortcode}>
                                                {price.size} - $ {price.price}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label className="mb-2">Crust:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={crust}
                                onChange={(e) => setCrust(e.target.value)}
                            >
                                {
                                    ing?.crust?.map((el) => {
                                        return <option value={el?.crustCode} key={el?.crustCode}>
                                            {el?.crustName}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                        <Col xs={12}>
                            <label className="mb-2">Crust Type:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={crustype}
                                onChange={(e) => setCrustType(e.target.value)}
                            >
                                {
                                    ing?.crustType?.map((el) => {
                                        return <option value={el?.crustTypeCode} key={el?.crustTypeCode}>
                                            {el?.crustType}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label className="mb-2">Cheese:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={cheese}
                                onChange={(e) => setCheese(e.target.value)}
                            >
                                {
                                    ing?.cheese?.map((el) => {
                                        return <option value={el?.cheeseCode} key={el?.cheeseCode}>
                                            {el?.cheeseName}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                        <Col xs={12}>
                            <label className="mb-2">Special Bases:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={specialBase}
                                onChange={(e) => setspecialBase(e.target.value)}
                            >
                                {
                                    ing?.specialbases?.map((el) => {
                                        return <option value={el?.specialbaseCode} key={el?.specialbaseCode}>
                                            {el?.specialbaseName}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label className="mb-2">Spicy:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={spicy}
                                onChange={(e) => setSpicy(e.target.value)}
                            >
                                {
                                    ing?.spices?.map((el) => {
                                        return <option value={el?.spicyCode} key={el?.spicyCode}>
                                            {el?.spicy}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                        <Col xs={12}>
                            <label className="mb-2">Sauce:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={sauce}
                                onChange={(e) => setSauce(e.target.value)}
                            >
                                {
                                    ing?.sauce?.map((el) => {
                                        return <option value={el?.sauceCode} key={el?.sauceCode}>
                                            {el?.sauce}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label className="mb-2">Cook:</label>
                            <select
                                style={{ width: "100%" }}
                                className="select-input sideSize mb-3 card-background-color card-text-color"
                                value={cook}
                                onChange={(e) => setCook(e.target.value)}
                            >
                                {
                                    ing?.cook?.map((el) => {
                                        return <option value={el?.cookCode} key={el?.cookCode}>
                                            {el?.cook}{"  "}${el?.price}
                                        </option>
                                    })
                                }
                            </select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="primary-background-color primary-text-color">
                <Button
                    variant="danger"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Close
                </Button>
                <Button className="tps_cart_btn"
                    onClick={handleAddToCart}
                >
                    Add to Cart
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default OtherModel;
