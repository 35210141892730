import React, { useEffect, useState } from "react";
import { getSignaturePizza } from "../services";
import CartFunction from "../components/cart";
import SignaturePizzas from "../components/_main/SignaturePizza/signturePizza";
import LoadingLayout from "../layouts/LoadingLayout";
import "../assets/styles/grid-cards.css";

function SignaturePizza({ toppingsData }) {
    const [signatureData, setSignatureData] = useState();
    const [loading, setLoading] = useState(true);
    const cartFn = new CartFunction();
    const signaturepizza = async () => {
        setLoading(true)
        await getSignaturePizza()
            .then((res) => {
                setLoading(false)
                setSignatureData(res.data);
            })
            .catch((err) => {
                console.log("Error From Get Sides :", err);
                setLoading(false)
            });
    };
    useEffect(() => {
        signaturepizza();
    }, []);

    return (
        <>
            {loading ? (<LoadingLayout />) : (
                <div className="section">
                    <div className="container">
                        <div className="grid-container">
                            {signatureData?.map((data) => {
                                return <SignaturePizzas data={data} key={data.sideCode} toppingsData={toppingsData} />;
                            })}
                        </div>
                    </div>
                </div >)
            }
        </>
    );
}

export default SignaturePizza;
