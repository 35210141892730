import React, { useEffect, useState } from "react";
import { getDrinks } from "../services";
import Drink from "../components/_main/DrinksOld/Drink";
import CartFunction from "../components/cart";
import LoadingLayout from "../layouts/LoadingLayout";
import "../assets/styles/grid-cards.css";

function DrinkMenu() {
    const [drinksData, setDrinksData] = useState();
    const [loading, setLoading] = useState(true);
    const cartFn = new CartFunction();

    const drinks = async () => {
        setLoading(true)
        await getDrinks()
            .then((res) => {
                setDrinksData(res.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log("Error from Get Drinks Data :", err);
                setLoading(false)
            });
    };

    useEffect(() => {
        drinks();
    }, []);
    return (
        <>
            {loading ? (<LoadingLayout />) : (
                <div className="section">
                    <div className="container">
                        <div className="grid-container">
                            {drinksData?.map((data, idx) => {
                                return (
                                    <Drink
                                        data={data}
                                        idx={idx}
                                        key={data?.softdrinkCode}
                                        cartFn={cartFn}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DrinkMenu;
