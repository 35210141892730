import React, { useContext, useEffect, useState } from 'react'
import LocationAccessContent from './LocationAccessContent'
import { LuBike } from 'react-icons/lu';
import { FaPeopleCarry } from 'react-icons/fa';
import GlobalContext from '../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function DeliveryPickupModalPopup({ setShow }) {
    const [currentTab, setCurrentTab] = useState('delivery');
    const globalCtx = useContext(GlobalContext);
    const [selectedType, setSelectedType] = globalCtx.selectedType;
    const [currentCity, setCurrentCity] = globalCtx.currentCity;
    const [currentStore, setCurrentStore] = globalCtx.currentStore;
    const navigate = useNavigate();

    const handleTabChange = (tabName) => {
        setCurrentTab(tabName);
        setSelectedType(tabName);
        localStorage.setItem('selectedType', tabName);
    };

    useEffect(() => {
        if (selectedType) {
            setCurrentTab(selectedType);
        }
    }, [selectedType]);

    const handleStartOrder = () => {
        if (currentCity && currentStore) {
            setShow(false);
            navigate('/menu');
        } else {
            toast.error('Please select a store to start your order');
        }
    }

    return (
        <div
            className="modal fade show"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Order Method</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setShow(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className='position-relative locationAccessModal'>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item " role="presentation">
                                    <a
                                        className={`rounded-start-5 nav-link ${currentTab === 'delivery' ? 'active' : ''}`}
                                        id="delivery-tab-0"
                                        data-bs-toggle="tab"
                                        href="#delivery-tabpanel-0"
                                        role="tab"
                                        aria-controls="delivery-tabpanel-0"
                                        aria-selected={currentTab === 'delivery'}
                                        onClick={() => handleTabChange('delivery')} // Update state on click
                                    >
                                        <LuBike /> Delivery
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a
                                        className={`rounded-end-5 nav-link ${currentTab === 'pickup' ? 'active' : ''}`}
                                        id="pickup-tab-1"
                                        data-bs-toggle="tab"
                                        href="#pickup-tabpanel-1"
                                        role="tab"
                                        aria-controls="pickup-tabpanel-1"
                                        aria-selected={currentTab === 'pickup'}
                                        onClick={() => handleTabChange('pickup')} // Update state on click
                                    >
                                        <FaPeopleCarry /> Pickup
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content pt-3" id="tab-content">
                                <LocationAccessContent currentTab={currentTab} isModal={true} setShow={setShow} /> {/* Pass the current tab as a prop */}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary rounded-5"
                            onClick={() => setShow(false)}
                            style={{ padding: '7px 14px' }}
                        >
                            Close
                        </button>
                        <button className={`btn btn-sm rounded-5 startOrderBtn  `} onClick={handleStartOrder}>Start Your Order</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryPickupModalPopup