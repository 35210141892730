import React, { useEffect, useState } from "react";

import { getSidesTypeWise } from "../services";
import LoadingLayout from "../layouts/LoadingLayout";

import "../assets/styles/grid-cards.css";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SideSlider from "../components/_main/Sides/SideSlider";
import CartFunction from "../components/cart";

function SidesMenu({ searchQuery }) {
    const [sidesData, setSideData] = useState();
    const [loading, setLoading] = useState(true);
    const cartFn = new CartFunction();

    const sides = async (searchQuery) => {
        setLoading(true);
        await getSidesTypeWise(searchQuery)
            .then((res) => {
                setSideData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("Error From Get Sides :", err);
                setLoading(false);
            });
    };

    useEffect(() => {
        sides(searchQuery);
    }, [searchQuery]);

    return (
        <>
            {loading ? (
                <LoadingLayout />
            ) : (
                sidesData?.map((data, index) => {
                    return (
                        <div className="section" key={index}>
                            <SideSlider data={data} cartFn={cartFn} />
                        </div>
                    );
                })
            )}
        </>
    );
}

export default SidesMenu;
