import React from 'react'
import Header from '../../components/_main/Header';
import Tabs from '../../components/Tabs/Tabs';
import SpecialOffer from '../SpecialOffer';
import Footer from '../../components/_main/Footer';
import '../../assets/styles/grid-cards.css';

function SpecialOfferList() {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <Header />
                <div className="nav-margin"></div>
                <Tabs />
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb custom-breadcrumb mt-5">
                            <li className="breadcrumb-item" aria-current="page">Home</li>
                            <li className="breadcrumb-item active" aria-current="page">Deals</li>
                        </ol>
                    </nav>
                </div>
                <SpecialOffer />
                <Footer />
            </div>
        </>
    )
}

export default SpecialOfferList