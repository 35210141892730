import { IoMdCheckmarkCircleOutline } from "react-icons/io";

export const CustomizeDrinksSelector = ({ data, selectedDrinksType, handleDrinksType }) => {
    const isSelected = selectedDrinksType === data;

    return (
        <div
            className={`${isSelected ? 'selected-card-background-color selected-card-text-color' : 'card-background-color card-text-color'}  py-3 px-3 mb-3 rounded-3`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleDrinksType(data)}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div className="fs-6 d-flex align-items-center gap-2">
                    <input
                        type="radio"
                        className="form-check-input"
                        checked={isSelected}
                        readOnly
                    />
                    <p className="fs-6">{data}</p>
                </div>
                <IoMdCheckmarkCircleOutline
                    color={isSelected ? '#90EE90' : 'transparent'}
                    size={25}
                />
            </div>
        </div>
    );
};
