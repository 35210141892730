import { useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io"
// push
export const ToppingOneSelector = ({ data, ToppingsOne, DefaultToppingsOne, handleTopping, handleSizeChange }) => {
    const [pizzaSize, setpizzaSize] = useState("whole");

    const handleChange = (d) => {
        setpizzaSize(d)
        if (ToppingsOne.some(obj => obj?.code === data?.toppingsCode)) {
            let updatedPrice = data?.price;
            const matchingToppingTwo = DefaultToppingsOne?.find(tps => data?.toppingsCode === tps.code);
            if (matchingToppingTwo) {
                updatedPrice = matchingToppingTwo?.price ?? 0
            }
            let payload = {
                code: data?.toppingsCode,
                name: data?.toppingsName,
                price: updatedPrice,
                type: "one",
                size: d
            }
            handleSizeChange(payload);
        }

    }

    return (
        <div className={`${ToppingsOne.some(obj => obj?.code === data?.toppingsCode) ? 'selected-card-background-color selected-card-text-color' : 'card-background-color card-text-color'}  py-3 px-3 mb-3 rounded-3`} style={{ cursor: "pointer" }} onClick={(e) => {
            if (e.target.tagName !== "SELECT" && e.target.tagName !== "OPTION") {
                handleTopping({
                    code: data?.toppingsCode,
                    name: data?.toppingsName,
                    price: data?.price,
                    type: "one",
                    size: pizzaSize,
                });
            }
        }}>
            <div className="d-flex justify-content-between align-items-center" >
                <div className="">
                    <div className="fs-6 d-flex align-items-center gap-2">
                        <input type="radio" className="form-check-input" checked={ToppingsOne.some(obj => obj?.code === data?.toppingsCode)} />
                        <p className="fs-6">{`${data?.toppingsName} ($ ${DefaultToppingsOne?.find(obj => obj?.code === data?.toppingsCode)?.price ?? data?.price
                            })`}</p>
                    </div>
                    <div className="mt-3 px-3">
                        <select className="px-2 outline-none rounded-1" value={ToppingsOne?.find((el) => el?.code === data?.toppingsCode)?.size || pizzaSize}
                            onChange={(e) => {
                                e.stopPropagation();
                                handleChange(e.target.value)
                            }
                            }
                        >
                            <option value={"whole"}>Whole</option>
                            <option value={"righthalf"}>Right Half</option>
                            <option value={"lefthalf"}>Left Half</option>
                            <option value={"1/4"}>1/4</option>
                        </select>
                    </div>
                </div>
                {ToppingsOne.some(obj => obj?.code === data?.toppingsCode) ? <IoMdCheckmarkCircleOutline color="#90EE90" size={25} /> : <IoMdCheckmarkCircleOutline color="transparent" size={25} />}
            </div>
        </div>
    )
}