import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { customerUpdatePassword } from "../../../services";
import Footer from "../Footer";
import Header from "../Header";
import { useNavigate, useParams } from "react-router-dom";
import LoadingLayout from "../../../layouts/LoadingLayout";

const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str}`;
};
const validationSchema = Yup.object({
    otp: Yup.string()
        .matches(/^[A-Z0-9]{6}$/, "OTP must consist of 6 characters, including uppercase letters and numbers")
        .required("Please enter the OTP"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must have at least 6 characters")
        .max(20, "Password cannot be longer than 20 characters")
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
    passwordconfirmation: Yup.string()
        .oneOf(
            [Yup.ref("password"), null],
            "Passwords and Confirm Password must be same"
        )
        .required("Confirm Password is required"),
});
function UpdatePassword() {
    const iniValues = {
        otp: "",
        password: "",
        passwordconfirmation: "",
    };

    const { token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const onSubmit = async (values) => {
        setLoading(true);
        const payload = {
            token: token,
            password: values?.password,
            password_confirmation: values?.passwordconfirmation,
            otp: values?.otp
        };
        await customerUpdatePassword(payload)
            .then((res) => {
                toast.success(res.message);
                navigate('/login');
                setLoading(false);
            })
            .catch((err) => {
                if (err.response.status === 400 || err.response.status === 500) {
                    toast.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const formik = useFormik({
        initialValues: iniValues,
        validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit,
        enableReinitialize: true,
    });
    useEffect(() => {
        const user = localStorage.getItem("user") ?? null;
        if (user != null) {
            const userData = JSON.parse(user);
            if (userData) {
                navigate("/");
            }
        }
        setLoading(false);
    }, [navigate]);

    if (loading) return <LoadingLayout />;

    return (
        <>
            <Header />
            <div className="nav-margin"></div>
            <div
                className="container-fluid d-flex justify-content-center align-items-center"
                style={{ minHeight: "calc(100vh - 400px)" }}
            >
                <div className="container row py-md-2 py-4 px-0 m-0" >
                    <div className="col-12 ">
                        <div className="row gx-3 justify-content-center align-content-center py-3">
                            <div className="content card-background-color col-lg-5 col-md-6 col-sm-12 rounded px-md-4 px-3 py-md-4 py-3">
                                <h3 className="mb-4">
                                    <strong>Change Password</strong>
                                </h3>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                                        <label className="form-label cPasswordLabel">
                                            OTP <small className="text-danger">*</small>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cPasswordInput"
                                            name="otp"
                                            value={formik.values.otp}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue("otp", e.target.value.trim());
                                            }}
                                            onBlur={formik.handleBlur}
                                        ></input>
                                        {formik.touched.otp && formik.errors.otp ? (
                                            <div className="text-danger formErrMsg mt-2 mb-1">
                                                {formik.errors.otp}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                                        <label className="form-label cPasswordLabel">
                                            Password <small className="text-danger">*</small>
                                        </label>
                                        <input
                                            className="form-control cPasswordInput"
                                            type="password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></input>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="text-danger formErrMsg mt-2 mb-1">
                                                {formik.errors.password}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 py-2">
                                        <label className="form-label cPasswordLabel">
                                            Confirm Password <small className="text-danger">*</small>
                                        </label>
                                        <input
                                            className="form-control cPasswordInput"
                                            type="password"
                                            name="passwordconfirmation"
                                            value={formik.values.passwordconfirmation}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></input>
                                        {formik.touched.passwordconfirmation &&
                                            formik.errors.passwordconfirmation ? (
                                            <div className="text-danger formErrMsg mt-2 mb-1">
                                                {formik.errors.passwordconfirmation}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-12 py-2 w-100 mb-3 mt-4">
                                        <button
                                            className="w-100 py-2 fw-bold btn btn-md rounded-5 text-uppercase loginBtn"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default UpdatePassword;