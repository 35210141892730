import React from 'react'
import CartFunction from '../../cart';
import pizzaLeft from "../../../assets/images/pizza-left.png";
import pizzaRight from "../../../assets/images/pizza-right.png";
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';
import OtherPizzas from '../../_main/OtherPizza/otherPizza';

function OtherPizzaSelector({ otherPizzas, topping }) {
    const cartFn = new CartFunction();
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="section-header" >
                        <h3 className="section-title" >
                            {/* <img src={pizzaLeft} alt="Left Pizza Icon" /> */}
                            Other Pizzas
                            {/* <img src={pizzaRight} alt="Right Pizza Icon" /> */}
                        </h3>
                        <Link className="more-link" to={`/otherpizza`}>
                            View All
                            <FaChevronRight />
                        </Link>
                    </div>
                    <div className="grid-container">
                        {otherPizzas?.map((data) => {
                            return <OtherPizzas data={data} key={data.code} cartFn={cartFn} toppingsData={topping} />;
                        })}
                    </div>
                </div>
            </div >
        </>
    )
}

export default OtherPizzaSelector