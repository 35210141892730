import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import "../../../assets/styles/grid-cards.css";

function Dips({ data, cartFn }) {
    const globalctx = useContext(GlobalContext);
    const [cart, setCart] = globalctx.cart;
    const [settings, setSettings] = globalctx.settings;
    const [currentStoreCode, setCurrentStoreCode] = globalctx.currentStoreCode;
    const [showStorePopup, setShowStorePopup] = globalctx.showStorePopup;

    const [count, setCount] = useState(1);
    const [product, setProduct] = useState(null);

    const user = useSelector((state) => state?.user);

    // Count Decrease
    const countDec = () => {
        if (count > 1) {
            setCount((count) => count - 1);
        }
    };
    // Count Increase
    const countInc = () => {
        if (count < 10) {
            setCount((count) => count + 1);
        }
    };
    // Handle Dips
    const handleDips = () => {
        if (!currentStoreCode) {
            setShowStorePopup(true)
            return false;
        }
        const totalPrice = data?.price * count;
        const obj = {
            id: uuidv4(),
            customerCode: user?.data?.customerCode,
            cashierCode: "#NA",
            productCode: data.dipsCode,
            productName: data.dipsName,
            productType: "dips",
            config: {},
            price: Number(data.price).toFixed(2),
            quantity: count,
            amount: Number(totalPrice).toFixed(2),
            taxPer: 0,
            pizzaSize: "",
            comments: "",
        };
        setProduct(obj);
        setCount(1);
    };

    useEffect(() => {
        cartFn.createCart(setCart);
    }, [setCart]);

    useEffect(() => {
        if (product !== null) {
            let ct = JSON.parse(localStorage.getItem("cart"));
            const pCode = ct?.product.find(
                (code) => code.productCode === product.productCode
            );
            if (pCode) {
                ct?.product.map((data) => {
                    if (data.productCode === pCode.productCode) {
                        pCode.quantity = pCode.quantity + product.quantity;
                        pCode.amount = Number(pCode.amount) + Number(product.amount);
                    }
                });
                const cartProduct = ct.product;
                cartFn.addCart(cartProduct, setCart, true, settings);
            } else {
                ct.product.push(product);
                const cartProduct = ct.product;
                cartFn.addCart(cartProduct, setCart, false, settings);
            }
        }
    }, [product]);

    return (
        <div className="m-card">
            <div
                className="grid-card-outer"
                key={"special-grid-card-" + data?.sideCode}
            >
                <div className="grid-card-placeholder">
                    <div className="grid-top-container">
                        <img
                            src={data?.image}
                            alt={data?.dipsName}
                            className="bestseller-placeholder"
                        />
                    </div>
                    <div className="grid-card-detail">
                        <div className="pizzaTitleDiv">
                            <div className="pizzaTitle">
                                <h5 className="mb-1">{data?.dipsName}</h5>
                            </div>
                        </div>
                        <div className="grid-card-pizzasize text-start">
                            <span className="primaryWhiteColor fw-bolder">
                                $ {data.price}
                            </span>
                        </div>
                        <div className="btn-container">
                            <div className="grid-card-left" style={{ userSelect: 'none' }}>
                                <span className={`cursor-pointer ${count <= 1 ? 'view-btn-disabled' : 'view-btn'}`} onClick={countDec}>
                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                </span>
                                <span className="countText " id="grid_count">
                                    {count}
                                </span>
                                <span className={`cursor-pointer ${count >= 10 ? 'view-btn-disabled' : 'view-btn'}`} onClick={countInc}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className="grid-card-right">
                                <span className="fill-view-btn cursor-pointer" onClick={handleDips}>
                                    ADD
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dips;
