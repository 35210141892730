import React, { useState, useEffect, memo, useContext } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Header from "../components/_main/Header";
import Footer from "../components/_main/Footer";
import SearchBox from "../components/_main/Stores/SearchBox";
import { Col, Container, Row } from "react-bootstrap";
import StoreCard from "../components/_main/Stores/StoresCard";
import mapMaker from "../assets/images/map-maker.png";
import { getStoreLocation, getStoreLocationByCity } from "../services";
import "../assets/styles/stores/storelocation.css";
import GlobalContext from "../context/GlobalContext";
import LoadingLayout from "../layouts/LoadingLayout";

const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    overflow: "hidden",
};

const Stores = () => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    });

    const [map, setMap] = React.useState(null);
    const [storeLocations, setStoreLocations] = React.useState([]);
    const [storeLocationByCity, setStoreLocationByCity] = React.useState([]);
    const [filteredStores, setFilteredStores] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedStoreCode, setSelectedStoreCode] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 51.168318, lng: -100.714792 }); // Default center (Canada)
    const [mapZoom, setMapZoom] = useState(3.7);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);

    const globalctx = useContext(GlobalContext);
    const [currentStoreCode, setCurrentStoreCode] = globalctx.currentStoreCode;
    const [currentCity, setCurrentCity] = globalctx.currentCity;
    const [currentStore, setCurrentStore] = globalctx.currentStore;
    const [currentLatitude, setCurrentLatitude] = globalctx.currentLatitude;
    const [currentLogitude, setCurrentLogitude] = globalctx.currentLogitude;

    const mapOptions = {
        zoomControl: false, // Remove zoom control
        fullscreenControl: false, // Remove fullscreen button
        streetViewControl: false, // Remove Street View control
        mapTypeControl: false, // Remove map type selector
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const handleClearSearch = () => {
        setSearchQuery(""); // Clear the search query
        if (currentLatitude && currentLogitude) {
            setMapCenter({ lat: currentLatitude, lng: currentLogitude }); // Reset map center
        }
        setMapCenter({ lat: 51.168318, lng: -100.714792 }); // Reset map center
        setMapZoom(3.7); // Reset map zoom
    };

    const handleMarkerClick = (store) => {
        setMapCenter({
            lat: parseFloat(store.latitude),
            lng: parseFloat(store.longitude),
        });
        setMapZoom(12);
        setActiveMarker(store.code);
        setSelectedStoreCode(store.code);
    };

    const fetchData = async () => {
        try {
            const [getStoreLocationData, getStoreLocationByCityData] = await Promise.all([
                getStoreLocation({
                    "lat": currentLatitude ?? '',
                    "long": currentLogitude ?? ''
                }),
                getStoreLocationByCity(),
            ]);

            setStoreLocations(getStoreLocationData?.data);
            setStoreLocationByCity(getStoreLocationByCityData.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData()
    }, [currentLatitude, currentLogitude])


    useEffect(() => {
        if (storeLocations?.length > 0 && currentStoreCode) {
            setMapZoom(12);
            setActiveMarker(currentStoreCode);
            const currentStoreData = storeLocations?.find((store) => store.code === currentStoreCode);
            setMapCenter({
                lat: parseFloat(currentStoreData.latitude),
                lng: parseFloat(currentStoreData.longitude),
            });
            setSelectedStoreCode(currentStoreCode);
        }
    }, [storeLocations, currentStoreCode]);

    useEffect(() => {
        // Filter stores based on search query
        const lowerQuery = (searchQuery || "").toLowerCase();
        if (!lowerQuery) {
            setFilteredStores(storeLocations); // Reset if search query is empty
        } else {
            setFilteredStores(
                storeLocations.filter(
                    (store) =>
                        store.storeLocation.toLowerCase().includes(lowerQuery) || // Match by store name
                        (store.city && store.city.toLowerCase().includes(lowerQuery)) // Match by city
                )
            );
        }
    }, [searchQuery, storeLocations]);

    if (loading) return <LoadingLayout />;

    return (
        <div id="location">
            <Header />
            <section className="new-block mt-3 primary-background-color">
                <div className="nav-margin"></div>
                <Container className="pb-3">
                    <Row className="justify-content-center align-items-start">
                        <Col xl={12} className="text-center mb-3">
                            <p className="fs-3 fw-bold primary-text-color">Store Locator</p>
                        </Col>
                        <Col xl={8} className="text-center mb-3">
                            <div className="map">
                                {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={mapCenter}
                                        zoom={mapZoom}
                                        onUnmount={onUnmount}
                                        options={mapOptions}
                                    >
                                        {filteredStores.map((store, index) => (
                                            <Marker
                                                key={store.code}
                                                position={{
                                                    lat: parseFloat(store.latitude),
                                                    lng: parseFloat(store.longitude),
                                                }}
                                                icon={mapMaker}
                                                title={store.storeLocation}
                                                label={
                                                    store.code === selectedStoreCode
                                                        ? {
                                                            text: store.storeLocation + "," + store.city,
                                                            color: "black",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                        }
                                                        : null
                                                }
                                            />
                                        ))}
                                    </GoogleMap>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Col>
                        <Col xl={4} className="text-center bg-light rounded-3 p-2">
                            <Row>
                                <Col xl={12}>
                                    <SearchBox
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        onClear={handleClearSearch}
                                    />
                                </Col>
                            </Row>
                            <div
                                className="store-list-container"
                                style={{ height: "450px", overflowY: "auto" }}
                            >
                                {filteredStores.map((store, index) => (
                                    <div className="col-12 w-100" key={`store-div-${index}`}>
                                        <StoreCard
                                            key={store.code}
                                            code={store.code}
                                            isBtnSelected={store.code === currentStoreCode}
                                            storeName={store.storeLocation}
                                            storeCity={store.city}
                                            address={store.storeAddress}
                                            latitude={store.latitude}
                                            longitude={store.longitude}
                                            storeDistance={store.distance}
                                            isNearestStore={store.isNearestStore}
                                            onCardClick={() => handleMarkerClick(store)}
                                            isActive={activeMarker === store.code}
                                            setCurrentStoreCode={setCurrentStoreCode}
                                            setCurrentStore={setCurrentStore}
                                            setCurrentCity={setCurrentCity}
                                            storeLocationByCity={storeLocationByCity}
                                            currentLatitude={currentLatitude}
                                            currentLogitude={currentLogitude}
                                            setCurrentLatitude={setCurrentLatitude}
                                            setCurrentLogitude={setCurrentLogitude}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default memo(Stores);
