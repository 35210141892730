import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import appLogo from "../../assets/images/logo.png";
import GlobalContext from "../../context/GlobalContext";
import '../../assets/styles/nav.css'
import { LuMenu, LuShoppingCart } from "react-icons/lu";
import DeliveryPickupModalPopup from "./DeliveryPickupModalPopup";

const Header = ({ isdemo }) => {
    // Global Context
    const location = useLocation();
    const globalCtx = useContext(GlobalContext);
    const [isAuthenticated, setIsAuthenticated] = globalCtx.auth;
    const [showSidebar, setShowSidebar] = globalCtx.sidebar;
    const [showStorePopup, setShowStorePopup] = globalCtx.showStorePopup;
    const [cart, setCart] = globalCtx.cart;
    const [dropMenu, setDropMenu] = useState(false);

    const handleDrawerToggle = () => {
        setDropMenu(!dropMenu);
    }

    const handleCartBarToggle = () => {
        setShowSidebar(!showSidebar)
    }
    return (
        <div className="customNav">
            <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-orange-dark" aria-label="Ninth navbar example">
                <div className={isdemo ? "container-fluid" : "container"}>
                    <div className="d-flex align-items-center gap-2">
                        <button className="navbar-toggler" onClick={handleDrawerToggle}>
                            <LuMenu size={32} />
                        </button>
                        <Link to={"/"} className="navbar-brand">
                            <img src={appLogo} alt="logo" className="logo" />
                        </Link>
                        <p className="brand-text">Mr Singh's<br />Pizza</p>
                    </div>

                    <div className="d-md-none d-flex align-items-center gap-3">
                        <button className="btn btn-light btn-sm orderButton" onClick={() => setShowStorePopup(true)}>Order Now</button>

                        <Link onClick={handleCartBarToggle} className="navbar-cart">
                            <LuShoppingCart size={18} />
                            <span className="nav-price">
                                $
                                {cart?.grandtotal
                                    ? cart?.grandtotal
                                    : (0.0).toFixed(2)}
                            </span>
                        </Link>
                    </div>
                    <div className={`collapse navbar-collapse ${dropMenu ? 'fade show' : ''}`} id="navbarsExample07XL" >
                        <ul className="navbar-nav ms-auto mb-lg-0" onClick={handleDrawerToggle}>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/" && "active"}`} aria-current="page" to={'/'}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/menu" && "active"}`} aria-current="page" to={'/menu'}>Menu</Link>
                            </li>

                            <li className="nav-item d-block d-md-none">
                                <Link className={`nav-link ${location.pathname === "/specialoffer" && "active"}`} to={'/specialoffer'}>Deals</Link>
                            </li>
                            <li className="nav-item d-block d-md-none">
                                <Link className={`nav-link ${location.pathname === "/signaturepizza" && "active"}`} to={'/signaturepizza'}>Signature Pizza</Link>
                            </li>
                            <li className="nav-item d-block d-md-none">
                                <Link className={`nav-link ${location.pathname === "/sides" && "active"}`} to={'/sides'}>Sides</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/stores" && "active"}`} to={'/stores'}>Stores</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/about-us" && "active"}`} to={'/about-us'}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === "/contact-us" && "active"}`} to={'/contact-us'}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                {
                                    !isAuthenticated ?
                                        (
                                            <Link className={`nav-link ${location.pathname == "/login" ? "active" : ""}`} to={"/login"}>Login</Link>
                                        ) : (
                                            <Link className={`nav-link ${location.pathname == "/my-account" ? "active" : ""}`} to={"/my-account"}>My Account</Link>
                                        )
                                }
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={"#"}
                                    onClick={() => setShowStorePopup(true)}
                                    className="d-md-block d-none btn btn-sm orderButton"
                                >
                                    Order Now
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={"#"}
                                    onClick={handleCartBarToggle}
                                    className="nav-link cart-link"
                                >

                                    <span className="nav-price">
                                        $
                                        {cart?.grandtotal
                                            ? cart?.grandtotal
                                            : (0.0).toFixed(2)}
                                    </span>
                                    <LuShoppingCart size={18} />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {showStorePopup ? <DeliveryPickupModalPopup show={showStorePopup} setShow={setShowStorePopup} /> : null}
            </nav >
            {/* <div className={`overlay ${dropMenu ? 'fade d-block' : 'd-none'}`} onClick={handleDrawerToggle}></div> */}
        </div >
    );
};

export default Header;
