import React from 'react'
import { GoDotFill } from 'react-icons/go'
import ShowToppings from './ShowSpecialPizzaConfig/ShowToppings'

function ShowSpecialPizzaConfig({ count, pizzaState, setPizzaState }) {
    // HANDLE TOPPINGS REMOVE
    const handleRemoveTopping = (el, type) => {
        setPizzaState((prevState) => {
            const updatedToppings = { ...prevState[count].toppings };
            updatedToppings[type] = updatedToppings[type].filter(
                (item) => item.toppingsCode !== el.toppingsCode
            );
            const newState = [...prevState];
            newState[count].toppings = updatedToppings;
            return newState;
        });
    }

    // HANDLE REMOVE IS INDIANS TOPPINGS
    const handleRemoveIsIndiansToppings = () => {
        setPizzaState((prevState) => {
            const updatedPizzaState = [...prevState];
            updatedPizzaState[count] = {
                ...updatedPizzaState[count],
                toppings: {
                    ...updatedPizzaState[count].toppings,
                    freeToppings: [],
                    isAllIndiansTps: false,
                },
            };
            return updatedPizzaState;
        });
    };


    return (
        <>
            {<div className='border-top pizza-card-border-color mt-2'>
                <div className='row'>
                    <div className="col-lg-6 p-2">
                        <div className="d-flex flex-column py-2">
                            <p className="fs-5 fw-bold primary-orange-color">  PIZZA - {count + 1}</p>
                        </div>
                    </div>
                </div>
            </div>}

            {pizzaState[count] && <>
                <div className="border-top pizza-card-border-color mt-1 py-3">
                    <div className="row">
                        <div className="col-lg-6">
                            {pizzaState[count]?.crust?.crustCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> Crust: {pizzaState[count]?.crust?.crustName} ($ {pizzaState[count]?.crust?.price})</p>}
                            {pizzaState[count]?.crustType?.crustTypeCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> Crust Type: {pizzaState[count]?.crustType?.crustTypeName} ($ {pizzaState[count]?.crustType?.price})</p>}
                            {pizzaState[count]?.cheese?.cheeseCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> Cheese: {pizzaState[count]?.cheese?.cheeseName} ($ {pizzaState[count]?.cheese?.price})</p>}
                        </div>
                        <div className="col-lg-6">
                            {pizzaState[count]?.sauce?.sauceCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> Sauce: {pizzaState[count]?.sauce?.crustName} ($ {pizzaState[count]?.sauce?.price})</p>}
                            {pizzaState[count]?.spicy?.spicyCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> Spicy: {pizzaState[count]?.spicy?.spicy} ($ {pizzaState[count]?.spicy?.price})</p>}
                            {pizzaState[count]?.cook?.cookCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> Cook: {pizzaState[count]?.cook?.cook} ($ {pizzaState[count]?.cook?.price})</p>}
                        </div>
                        <div className="col-lg-12">
                            {pizzaState[count]?.specialBases?.specialbaseCode && <p className="lh-sm fs-6 mt-2 "><GoDotFill /> SpecialBases: {pizzaState[count]?.specialBases?.specialbaseName} ($ {pizzaState[count]?.specialBases?.price})</p>}
                        </div>
                    </div>
                </div>
                {pizzaState[count]?.toppings &&
                    <ShowToppings toppings={pizzaState[count]?.toppings} handleRemoveTopping={handleRemoveTopping} handleRemoveIsIndiansToppings={handleRemoveIsIndiansToppings} />
                }
            </>}
        </>
    )
}

export default ShowSpecialPizzaConfig