import React from 'react'
import '../assets/styles/privacypolicy.css'
import Header from '../components/_main/Header'
import Footer from '../components/_main/Footer'

function PrivacyPolicy() {
    return (
        <>
            <Header />
            <div className="new-block my-5">
                <div className="nav-margin"></div>
                <div className='container'>
                    <div className='para mb-5'>
                        <p className='fs-2 fw-bold paraTitle'>Privacy Policy</p>
                    </div>
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle'></p>
                        <p className='fs-6 text'>
                            Mr. Singh’s Pizza ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, use our online ordering platform, or interact with us through telecalling or in-store services. By using our services, you agree to the terms of this Privacy Policy.
                        </p>
                    </div>
                    {/* 1 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>1. Information We Collect</p>
                        <p className='fs-6 text'>
                            We collect the following types of information to provide and improve our services:
                        </p>
                        <p className='fs-6 fw-bold text paraSubTitle'>
                            a. Personal Information:
                        </p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>Name</p></li>
                            <li><p className='fs-6 text'>Contact details (phone number, email address, address)</p></li>
                            <li><p className='fs-6 text'>Payment information (if applicable)</p></li>
                        </ul>
                        <p className='fs-6 fw-bold text paraSubTitle'>
                            b. Non-Personal Information:
                        </p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>Browser type, operating system, and IP address</p></li>
                            <li><p className='fs-6 text'>Pages visited, time spent, and other website usage data</p></li>
                        </ul>
                    </div>
                    {/* 2 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>2. How We Use Your Information</p>
                        <p className='fs-6 text'>
                            We use the information we collect for the following purposes:
                        </p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Order Processing:</strong> To process and deliver your orders, whether placed online, for pickup, or through telecalling.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Customer Support:</strong> To respond to your inquiries and provide assistance.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Communication:</strong> To send order confirmations, updates, or promotional offers (you may opt out of marketing communications).
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Improvement of Services:</strong> To analyze website usage and improve our offerings.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Legal Compliance:</strong> To comply with applicable laws and regulations.
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* 3 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>3. Sharing Your Information</p>
                        <p className='fs-6 text'>
                            We do not sell your information to third parties. However, we may share your information in the following cases:
                        </p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Service Providers:</strong> With third-party vendors who assist in processing payments or delivering orders.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Legal Obligations:</strong> To comply with legal requirements, court orders, or governmental requests.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Business Transfers:</strong> In the event of a merger, sale, or acquisition, your information may be transferred as part of the business assets.
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* 4 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>4. Data Security</p>
                        <p className='fs-6 text'>
                            We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no online system is completely secure, and we cannot guarantee absolute security.
                        </p>
                    </div>
                    {/* 5 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>5. Your Rights</p>
                        <p className='fs-6 text'>You have the following rights regarding your information:</p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Access and Correction:</strong> Request access to or correction of your personal information.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Data Deletion:</strong> Request the deletion of your data, subject to legal or contractual obligations.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Opt-Out:</strong> Opt-out of receiving promotional communications.
                                </p>
                            </li>
                        </ul>
                        <p className='fs-6 text'>
                            To exercise these rights, contact us at <a className='text-decoration-none' href="mailto:Mrsinghpizza@hotmail.com">Mrsinghpizza@hotmail.com</a> or call 9055004000.
                        </p>
                    </div>
                    {/* 6 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>6. Cookies and Tracking Technologies</p>
                        <p className='fs-6 text'>
                            We use cookies and similar tracking technologies to enhance your browsing experience. You can adjust your browser settings to refuse cookies, but this may limit your use of certain website features.
                        </p>
                    </div>
                    {/* 7 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>7. Third-Party Links</p>
                        <p className='fs-6 text'>
                            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.
                        </p>
                    </div>
                    {/* 8 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>8. Children’s Privacy</p>
                        <p className='fs-6 text'>
                            Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe we have collected such information, contact us to have it removed.
                        </p>
                    </div>
                    {/* 9 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>9. Updates to This Policy</p>
                        <p className='fs-6 text'>
                            We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date.
                        </p>
                    </div>
                    {/* 10 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>10. Contact Us</p>
                        <p className='fs-6 text'>
                            If you have any questions or concerns about this Privacy Policy or how your information is handled, please contact us:
                        </p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Email:</strong> <a className='text-decoration-none' href="mailto:Mrsinghpizza@hotmail.com">Mrsinghpizza@hotmail.com</a>
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Phone:</strong> 9055004000
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Address:</strong> 2130 North Park Drive, Brampton, ON, CA L6S 5M4
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default PrivacyPolicy