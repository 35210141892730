import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Header from "../components/_main/Header";
import Footer from "../components/_main/Footer";
import { useSelector } from "react-redux";
import {
    deliverable,
    getPostalcodeList,
    getStoreLocationByCity,
    orderPlace,
    settingApi,
    zipcodeServicable,
} from "../services";
import { toast } from "react-toastify";
import { json, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import GlobalContext from "../context/GlobalContext";
import Select from "react-select";
import LoadingLayout from "../layouts/LoadingLayout";
import { useSocket } from "../context/SocketContext";

const canadianPhoneNumberRegExp = /^\d{3}\d{3}\d{4}$/;
const canadianPostalCode = Yup.string().test(
    "is-canadian-postal-code",
    "Invalid Canadian Postal Code",
    (value) => {
        if (!value) return true;
        const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
        return postalCodeRegex.test(value);
    }
);

const ValidateSchema = Yup.object({
    firstname: Yup.string()
        .required("First name is required")
        .matches(
            /^[A-Za-z\ ]+$/,
            "First name should only contain alphabetic characters, spaces"
        )
        .min(3, "First name must be at least 3 characters")
        .max(50, "First name cannot be longer than 50 characters"),
    lastname: Yup.string()
        .required("Last name is required")
        .matches(
            /^[A-Za-z\ ]+$/,
            "Last name should only contain alphabetic characters, spaces"
        )
        .min(3, "Last name must be at least 3 characters")
        .max(50, "Last name cannot be longer than 50 characters"),
    phoneno: Yup.string()
        .required("Phone number is required")
        .matches(
            canadianPhoneNumberRegExp,
            "Invalid Canadian phone number format. Use (XXX) XXX-XXXX."
        ),
    city: Yup.string()
        .required("City is required")
        .matches(
            /^[A-Za-z\ ]+$/,
            "City name should only contain alphabetic characters, spaces"
        )
        .min(3, "City must be at least 3 characters")
        .max(50, "City cannot be longer than 50 characters"),
    postalcode: canadianPostalCode.required("Postal Code is Required"),
    address: Yup.string()
        .required("Address is required")
        .min(10, "Address must be at least 10 characters")
        .max(100, "Address cannot be longer than 100 characters"),
});

function AddressDetails() {
    const socket = useSocket();
    const user = useSelector((state) => state.user);
    const globalctx = useContext(GlobalContext);
    const [cart, setCart] = globalctx.cart;
    const [currentStoreCode, setCurrentStoreCode] = globalctx.currentStoreCode;
    const [currentCity, setCurrentCity] = globalctx.currentCity;
    const [currentStore, setCurrentStore] = globalctx.currentStore;

    const [postalCodeOp, setPostalCodeOp] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deliveryCh, setDeliveryCh] = useState(0.0);

    const [taxRates, setTaxRates] = useState(null);
    const [grand_total, setGrandTotal] = useState(0);
    const [tax_amount, setTax_amount] = useState(0);
    const [tax_percent, setTax_percent] = useState(0);
    const [readOnly, setReadOnly] = useState(false)

    const [selectedStore, setSelectedStore] = useState(null);
    const [cities, setCities] = useState([]);
    const [stores, setStores] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);


    const [initialValues, setInitialValues] = useState({
        firstname: user?.data?.firstName,
        lastname: user?.data?.lastName,
        phoneno: user?.data?.mobileNumber,
        postalcode: "",
        city: "",
        address: "",
    });

    const navigate = useNavigate();

    const postalCodeList = async () => {
        if (formik.values.postalcode.length >= 3) {
            await getPostalcodeList({
                search: formik.values.postalcode,
            })
                .then((res) => {
                    setTimeout(() => {
                        setPostalCodeOp(res.data);
                    }, 200);
                })
                .catch((err) => {
                    if (err.response.status === 400 || err.response.status === 500) {
                        toast.error(err.response.data.message);
                    }
                });
        } else {
            setPostalCodeOp([]);
        }
    };

    // Fetch data from API
    const fetchData = async () => {
        try {
            const res = await getStoreLocationByCity();
            const cityOptions = res.data.map((item) => ({
                value: item.city,
                label: item.city,
                stores: item.storeLocations,
            }));
            setCities(cityOptions);
        } catch (error) {
            toast.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setSelectedCity(currentCity)
        setSelectedStore(currentStore)
        const storeOptions = currentCity?.stores.map((store) => ({
            value: store.code,
            label: store.storeLocation,
        }));
        setStores(storeOptions);
    }, [currentCity, currentStore])

    // Developer: Shreyas Mahamuni, Working Date: 26-12-2023
    const settingValues = async () => {
        await settingApi()
            .then((res) => {
                if (res) {
                    res.data.map((data) => {
                        if (data?.settingCode === "STG_1" && data?.type === "amount") {
                            setDeliveryCh(data?.settingValue);
                        }
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const paymentGateway = (values) => {
        let deliveryCharges = deliveryCh || 0.0;

        let custFullName = values.firstname + " " + values?.lastname;
        const payload = {
            customerCode: user?.data?.customerCode,
            deliveryType: "delivery",
            customerName: custFullName,
            mobileNumber: values?.phoneno,
            address: values?.address,
            zipCode: values?.postalcode,
            products: cart?.product,
            subTotal: cart?.subtotal,
            discountAmount: cart?.discountAmount,
            taxPer: tax_percent,
            taxAmount: tax_amount,
            deliveryCharges: deliveryCharges,
            extraDeliveryCharges: cart?.extraDeliveryCharges,
            grandTotal: grand_total,
        };

        orderPlace(payload)
            .then((response) => {
                const socketOrderData = response.data;
                socket.emit("order-place", socketOrderData);
                localStorage.setItem("placedOrder", JSON.stringify(response));
                navigate("/order/verify");
                setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 400 || error.response.status === 500) {
                    if (error.response.data.isStoreError === true) {
                        swal({
                            title: "Store has been closed.",
                            text: `Unfortunately, placing an order is not possible at the moment. You can not place order right now.`,
                            icon: "warning",
                            buttons: "Ok",
                            dangerMode: true,
                        }).then(async (willOk) => {
                            if (willOk) {
                                navigate("/address-details");
                            }
                        });
                    } else {
                        toast.error(error.response.data.message);
                    }
                }
                setLoading(false);
            });
    };

    // Handle city selection
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setCurrentCity(selectedOption);
        localStorage.setItem('currentCity', JSON.stringify(selectedOption));
        setSelectedStore(null);
        setCurrentStore(null);
        setCurrentStoreCode(null);
        localStorage.setItem('currentStoreCode', null)
        localStorage.setItem('currentStore', null)
        const storeOptions = selectedOption.stores.map((store) => ({
            value: store.code,
            label: store.storeLocation,
        }));
        setStores(storeOptions);
    };

    // Handle store selection
    const handleStoreChange = (selectedOption) => {
        setCurrentStoreCode(selectedOption.value)
        setCurrentStore(selectedOption)
        localStorage.setItem('currentStoreCode', selectedOption.value)
        localStorage.setItem('currentStore', JSON.stringify(selectedOption))
        setSelectedStore(selectedOption);
        toast.success('Store selected successfully.');
    };

    const onSubmit = async (values) => {
        const payload = {
            zipcode: values.postalcode,
        };
        console.log("currentStoreCode", currentStoreCode);
        if (!currentStoreCode) {
            toast.error('Please select the nearest store');
            return;
        }

        try {
            // Check if the postal code is deliverable for the current store
            const storeResponse = await zipcodeServicable({ zipcode: values?.postalcode, storeCode: currentStoreCode });
            if (!storeResponse?.deliverable) {
                return handleUndeliverable("Postal Code is Undeliverable with selected store",
                    "Postal code cannot be delivered. Please change the postal code or store and try again");
            }
            // Fetch deliverability details and calculate costs
            const deliveryResponse = await deliverable(payload);
            if (!deliveryResponse?.deliverable) {
                return handleUndeliverable("Postal Code is Undeliverable",
                    "Postal code cannot be delivered. Please change the postal code and try again");
            }
            // Calculate tax and grand total
            const taxPercent = Number(deliveryResponse?.taxRates?.tax_percent || 0).toFixed(2);
            const taxAmount = Number((cart?.subtotal || 0) * taxPercent * 0.01).toFixed(2);
            let grandTotal = Number((Number(cart?.grandtotal) || 0) + Number(taxAmount)).toFixed(2);
            if (deliveryCh > 0) {
                grandTotal = Number(grandTotal) + Number(deliveryCh);
            }

            setTax_amount(taxAmount);
            setGrandTotal(grandTotal);
            setTax_percent(taxPercent);
            setTaxRates(deliveryResponse?.taxRates);
            setReadOnly(true);
        } catch (error) {
            setLoading(false);

            if (error.response?.status === 400 || error.response?.status === 500) {
                toast.error(error.response?.data?.message || "Something went wrong. Please try again.");
            } else {
                console.error("Unexpected error:", error);
                toast.error("An unexpected error occurred.");
            }
        }
    };

    const handleUndeliverable = (title, text) => {
        swal({
            title,
            text,
            icon: "warning",
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    closeModal: true, // Ensures the modal closes
                },
            },
            dangerMode: true,
        }).then((willOk) => {
            if (willOk) {
                navigate("/checkout-page");
            }
        });
    };



    const handleUseAddress = () => {
        formik.setValues({
            ...formik.values,
            address: user?.data?.address,
            city: user?.data?.city,
            postalcode: user?.data?.zipcode,
        });
    };
    // Use Formik
    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: true,
        validationSchema: ValidateSchema,
        enableReinitialize: true,
        onSubmit,
    });

    useEffect(() => {
        postalCodeList();
    }, [formik.values.postalcode]);

    useEffect(() => {
        settingValues();
    }, []);

    useEffect(() => {
        if (deliveryCh > 0) {
            let total = Number(cart?.grandtotal) + Number(deliveryCh);
            let totalPay = Number(total).toFixed(2);
            setGrandTotal(totalPay);
        }
        else {
            let total = Number(cart?.grandtotal)
            let totalPay = Number(total).toFixed(2);
            setGrandTotal(totalPay);
        }
    }, [deliveryCh])

    if (loading) return <LoadingLayout />;

    return (
        <div className="relative" style={{ paddingBottom: "200px" }}>
            <div
                className="container-fluid d-flex justify-content-start align-items-start flex-column p-0 m-0"
                style={{ backgroundColor: "#ffffff" }}
            >
                <div className="row justify-content-start checkout_pg">
                    <h1 className="titleColor mb-1">Delivery</h1>
                    <p className="subTitleColor mb-4">Address Details For Checkout :</p>
                </div>
                <div className="container-fluid row w-100 p-0 m-0">
                    <div className="col-xl-6 col-lg-7 col-md-7 col-sm-12 px-2 p-0 m-0">
                        <div className="row gx-3">
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1'>
                                <p className="fs-6 fw-bold text-secondary">Nearest Store</p>
                            </div>
                            {/* City Dropdown */}
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-1'>
                                <label htmlFor="city-select" className="form-label">City:</label>
                                <Select
                                    id="city-select"
                                    options={cities}
                                    value={selectedCity}
                                    onChange={handleCityChange}
                                    placeholder="Select a city..."
                                    isSearchable
                                    menuPortalTarget={document.body} // Render dropdown outside of the overflow container
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        container: (base) => ({ ...base, width: '100%' }),
                                        control: (base) => ({ ...base, width: '100%' }),
                                        menu: (base) => ({ ...base, width: '100%' }),
                                    }}
                                    aria-label="City Select"
                                />
                            </div>
                            {/* Store Dropdown */}
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-1 '>
                                <label htmlFor="store-select" className="form-label">Store:</label>
                                <Select
                                    id="store-select"
                                    options={stores}
                                    value={selectedStore}
                                    onChange={handleStoreChange}
                                    placeholder={
                                        selectedCity ? "Select a store..." : "Select a city first..."
                                    }
                                    menuPortalTarget={document.body} // Render dropdown outside of the overflow container
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        container: (base) => ({ ...base, width: '100%' }),
                                        control: (base) => ({ ...base, width: '100%' }),
                                        menu: (base) => ({ ...base, width: '100%' }),
                                    }}
                                    isSearchable
                                    isDisabled={!selectedCity}
                                    aria-label="Store Select"
                                />
                            </div>
                            <div className="content border-top col-lg-12 col-md-12 col-sm-12 rounded">
                                <form className="w-100" onSubmit={formik.handleSubmit}>
                                    <div className="row gx-3">
                                        <div className="mb-1 py-2 p-0 m-0 row justify-content-center align-items-center">
                                            <div className="col-lg-8 col-md-7 col-12 text-wrap pb-2">
                                                <span className="fw-bolder text-secondary delivery_addressTxt">
                                                    {user?.data?.address}, {user?.data?.city},
                                                    {user?.data?.zipcode}
                                                </span>
                                            </div>
                                            <div className="col-lg-4 col-md-5 col-12 text-md-end text-start">
                                                <button
                                                    className="btn btn-sm btn-secondary shadow-sm fw-bold"
                                                    type="button"
                                                    onClick={handleUseAddress}
                                                    disabled={readOnly}
                                                    style={{
                                                        fontSize: "0.82rem",
                                                    }}
                                                >
                                                    Use This Address
                                                </button>
                                            </div>
                                        </div>

                                        {/* Address */}
                                        <div className="border-top col-lg-12 col-md-12 col-sm-12 pt-2">
                                            <label className="form-label">
                                                Address <small className="text-danger">*</small>
                                            </label>
                                            {console.log(formik.values.address)}
                                            <input
                                                className="form-control mb-1"
                                                type="text"
                                                name="address"
                                                disabled={readOnly}
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        {formik.touched.address && formik.errors.address ? (
                                            <div className="text-danger mt-1 mb-1">
                                                {formik.errors.address}
                                            </div>
                                        ) : null}

                                        {/* City */}
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <label className="form-label">
                                                City <small className="text-danger">*</small>
                                            </label>
                                            <input
                                                className="form-control mb-1"
                                                type="text"
                                                name="city"
                                                disabled={readOnly}
                                                value={formik.values.city}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.city && formik.errors.city ? (
                                                <div className="text-danger my-1">
                                                    {formik.errors.city}
                                                </div>
                                            ) : null}
                                        </div>

                                        {/* Postal Code */}
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <label className="form-label">
                                                Postal Code <small className="text-danger">*</small>
                                            </label>
                                            <input
                                                className="form-control mb-1"
                                                type="text"
                                                id="postalcode"
                                                name="postalcode"
                                                list="options"
                                                disabled={readOnly}
                                                placeholder="Select Option"
                                                onChange={formik.handleChange}
                                                value={formik.values.postalcode}
                                                autoComplete="off"
                                            />
                                            <datalist id="options">
                                                {postalCodeOp?.map((option) => {
                                                    return (
                                                        <option
                                                            key={option.code}
                                                            value={option.zipcode}
                                                        />
                                                    );
                                                })}
                                            </datalist>

                                            {formik.touched.postalcode &&
                                                formik.errors.postalcode ? (
                                                <div className="text-danger my-1">
                                                    {formik.errors.postalcode}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="my-1 d-flex justify-content-start align-items-center flex-row">
                                            <strong className="mb-2 me-4">Payment Mode : </strong>
                                            <span className="mb-2 fw-bolder text-danger">
                                                Pay on Delivery
                                            </span>
                                        </div>
                                        {!readOnly && <div className="d-flex gap-4 mb-2">
                                            <button
                                                className="py-2 fw-bold btn btn-md regBtn"
                                                type="submit"
                                            >
                                                Check deliverable
                                            </button>
                                        </div>}
                                        {readOnly && <div className="d-flex gap-4 mb-2">
                                            <button
                                                className="py-2 fw-bold btn btn-md regBtn"
                                                type="button"
                                                onClick={() => setReadOnly(false)}
                                            >
                                                Change address
                                            </button>
                                        </div>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-12 px-2 p-0 m-0 summary-unfixed-box">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <div className="block-stl10 odr-summary mb-0">
                                    <h3>Order Summary :</h3>
                                    <ul className="list-unstyled">
                                        <li>
                                            <span className="ttl">Sub Total</span>
                                            <span className="stts">
                                                $ {cart?.subtotal ? cart?.subtotal : (0.0).toFixed(2)}
                                            </span>
                                        </li>
                                        {taxRates && <li>
                                            <span className="ttl">
                                                Tax Amount ( {tax_percent} % )
                                            </span>
                                            <span className="stts">
                                                $
                                                {tax_amount}
                                            </span>
                                        </li>}
                                        <li className="d-none">
                                            <span className="ttl">Convenience Charges (%)</span>
                                            <span className="stts">
                                                {cart?.convinenceCharges
                                                    ? cart?.convinenceCharges
                                                    : 0}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="ttl">Delivery Charges</span>
                                            <span className="stts">
                                                $
                                                {deliveryCh
                                                    ? Number(deliveryCh).toFixed(2)
                                                    : Number(0).toFixed(2)}
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="ttl-all" id="font-size" >
                                        <span className="ttlnm">Grand Total</span>
                                        <span className="odr-stts total-font-size" >
                                            ${grand_total ? Number(grand_total).toFixed(2) : (0.0).toFixed(2)}
                                        </span>
                                    </div>
                                    {readOnly && <div className="mt-5 float-end">
                                        <button
                                            className="py-2 fw-bold btn btn-md btn-success"
                                            type="button"
                                            onClick={() => paymentGateway(formik.values)}
                                        >
                                            Place my order
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="summary-fixed-box">
                <h3>Order Summary :</h3>
                <div className="row">
                    <div className="col-12 filled-bx">
                        <span className="">Sub Total</span>
                        <span className="">
                            ${cart?.subtotal ? cart?.subtotal : (0.0).toFixed(2)}
                        </span>
                    </div>
                    {taxRates && (
                        <div className="col-12 filled-bx">
                            <span className="">
                                Tax Amount ({tax_percent} %)
                            </span>
                            <span className="">
                                ${tax_amount}
                            </span>
                        </div>
                    )}
                    <div className="col-12 filled-bx">
                        <span className="">Delivery Charges</span>
                        <span className="">
                            ${deliveryCh
                                ? Number(deliveryCh).toFixed(2)
                                : Number(0).toFixed(2)}
                        </span>
                    </div>
                    <div className="col-12 filled-bx">
                        <strong className="text-grey">Grand Total</strong>
                        <strong className="text-grey">
                            ${grand_total ? Number(grand_total).toFixed(2) : (0.0).toFixed(2)}
                        </strong>
                    </div>
                    {readOnly && (
                        <div className="col-12 mt-1">
                            <button
                                className="btn btn-md w-100 regBtn"
                                onClick={() => paymentGateway(formik.values)}
                            >
                                Place my order
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AddressDetails;
