import React from 'react'

import pizzaLeft from "../../../assets/images/pizza-left.png";
import pizzaRight from "../../../assets/images/pizza-right.png";
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';
import CartFunction from '../../cart';
import Drink from '../../_main/DrinksOld/Drink';

function DrinksSelector({ drinks }) {
    const cartFn = new CartFunction();
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="section-header" >
                        <h3 className="section-title" >
                            {/* <img src={pizzaLeft} alt="Left Pizza Icon" /> */}
                            Drinks
                            {/* <img src={pizzaRight} alt="Right Pizza Icon" /> */}
                        </h3>
                        <Link className="more-link" to={`/drinks`}>
                            View All
                            <FaChevronRight />
                        </Link>
                    </div>
                    <div className="grid-container">
                        {drinks?.map((data, idx) => {
                            return <Drink
                                data={data}
                                idx={idx}
                                key={data?.softdrinkCode}
                                cartFn={cartFn}
                            />
                        })}
                    </div>
                </div>
            </div >
        </>
    )
}

export default DrinksSelector