import { IoMdCheckmarkCircleOutline } from "react-icons/io"

export const SauceSelector = ({ data, Sauce, handleSauce }) => {
    return (
        <div className={`${Sauce === data?.sauceCode ? 'selected-card-background-color selected-card-text-color' : 'card-background-color card-text-color'}  py-3 px-3 mb-3 rounded-3`} style={{ cursor: "pointer" }} onClick={() => handleSauce(data?.sauceCode)}>
            <div className="d-flex justify-content-between align-items-center" >
                <div className="fs-6 d-flex align-items-center gap-2"><input type="radio" className="form-check-input" checked={Sauce === data?.sauceCode} /><p className="fs-6">{`${data?.sauce} ($ ${data?.price})`}</p></div>
                {Sauce === data?.sauceCode ? <IoMdCheckmarkCircleOutline color="#90EE90" size={25} /> : <IoMdCheckmarkCircleOutline color="transparent" size={25} />}
            </div>
        </div>
    )
}