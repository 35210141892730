import React from 'react'
import pizzaLeft from "../../../assets/images/pizza-left.png";
import pizzaRight from "../../../assets/images/pizza-right.png";
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';
import SpecialOffers from '../../_main/SpecialOffer/SpecialOffers';

function SpecialOfferSelector({ specialOffers }) {
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="section-header" >
                        <h3 className="section-title" >
                            {/* <img src={pizzaLeft} alt="Left Pizza Icon" /> */}
                            Deals
                            {/* <img src={pizzaRight} alt="Right Pizza Icon" /> */}
                        </h3>
                        <Link className="more-link" to={`/specialoffer`}>
                            View All
                            <FaChevronRight />
                        </Link>
                    </div>
                    <div className="grid-container">
                        {specialOffers?.map((data) => {
                            return (
                                <SpecialOffers key={data.code} data={data} />
                            );
                        })}
                    </div>
                </div>
            </div >
        </>
    )
}

export default SpecialOfferSelector