import React, { useContext, useEffect } from "react";
import GlobalContext from "../../../context/GlobalContext";
import CartFunction from "../../cart";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/styles/Cart/CartList.css";
import { data } from "jquery";
import { toast } from "react-toastify";

function CartList({
    cData,
    setPayloadEdit,
    payloadEdit,
    // resetControls,
    setLoading,
}) {
    // GlobalContext
    const globalctx = useContext(GlobalContext);
    const [cart, setCart] = globalctx.cart;
    const [settings, setSettings] = globalctx.settings;
    const [showSidebar, setShowSidebar] = globalctx.sidebar;
    // Helper Function
    const cartFn = new CartFunction();
    //
    const location = useLocation();
    const navigate = useNavigate();

    // const handleDelete = (e) => {
    //     e.preventDefault();
    //     if (cart?.product?.length === 1) {
    //         if (payloadEdit) {
    //             if (payloadEdit?.id === cData?.id) {
    //                 setPayloadEdit();
    //                 // resetControls();
    //                 cartFn.deleteCart(cData, cart, setCart, settings);
    //                 localStorage.removeItem("cart");
    //                 setCart();
    //                 cartFn.createCart(setCart);
    //             }
    //         } else {
    //             cartFn.deleteCart(cData, cart, setCart, settings);
    //             localStorage.removeItem("cart");
    //             setCart();
    //             cartFn.createCart(setCart);
    //         }
    //     } else {
    //         if (payloadEdit) {
    //             if (payloadEdit?.id === cData?.id) {
    //                 setPayloadEdit();
    //                 // resetControls();
    //                 cartFn.deleteCart(cData, cart, setCart, settings);
    //             } else {
    //                 cartFn.deleteCart(cData, cart, setCart, settings);
    //             }
    //         } else {
    //             cartFn.deleteCart(cData, cart, setCart, settings);
    //         }
    //     }
    // };


    const handleDelete = (e) => {
        e.preventDefault();

        const currentPath = location.pathname;
        const { productType, id, productCode } = cData || {};
        const productRoutes = {
            custom_pizza: `/create-your-own/${id}`,
            special_pizza: `/specialoffer/${id}/${productCode}`,
            signature_pizza: `/signaturepizza/${id}/${productCode}`,
            other_pizza: `/otherpizza/${id}/${productCode}`,
        };

        const deleteUrlForItem = productRoutes[productType] || "";
        const isEditingSameItem = currentPath === deleteUrlForItem;

        if (isEditingSameItem) {
            toast.error("You cannot delete this item, The Item is in editing mode");
            return false;
        }

        const isSingleProduct = cart?.product?.length === 1;

        cartFn.deleteCart(cData, cart, setCart, settings);

        if (isSingleProduct) {
            localStorage.removeItem("cart");
            setCart();
            cartFn.createCart(setCart);
        }
    };


    const handleCurrentEdit = () => {
        if (payloadEdit === undefined) {
            setPayloadEdit(cData);
        } else {
            setPayloadEdit();
            // resetControls();
            setLoading(true);
            setTimeout(() => {
                setPayloadEdit(cData);
                setLoading(false);
            }, 1200);
        }
    };
    const handleRedirectToEdit = (pathUrl) => {
        if (payloadEdit === undefined) {
            navigate(pathUrl);
            setPayloadEdit(cData);
        } else {
            setPayloadEdit();
            // resetControls();
            setLoading(true);
            setTimeout(() => {
                navigate(pathUrl);
            }, 800);
            setTimeout(() => {
                setPayloadEdit(cData);
                setLoading(false);
            }, 1200);
        }
    };

    const handleEdit = () => {
        if (cData?.productType === "custom_pizza") {
            if (location.pathname === `/create-your-own/${cData?.id}`) {
                handleCurrentEdit();
                setShowSidebar(false)
            } else {
                handleRedirectToEdit(`/create-your-own/${cData?.id}`);
                setShowSidebar(false)
            }
        }
        if (cData?.productType === "special_pizza") {
            if (location.pathname === `/specialoffer/${cData?.id}/${cData?.productCode}`) {
                handleCurrentEdit();
                setShowSidebar(false);
            } else {
                handleRedirectToEdit(`/specialoffer/${cData?.id}/${cData?.productCode}`);
                setShowSidebar(false);
            }
        }

        if (cData?.productType === "signature_pizza") {
            if (location.pathname === `/signaturepizza/${cData?.id}/${cData?.productCode}`) {
                handleCurrentEdit();
                setShowSidebar(false);
            } else {
                handleRedirectToEdit(`/signaturepizza/${cData?.id}/${cData?.productCode}`);
                setShowSidebar(false);
            }
        }

        if (cData?.productType === "other_pizza") {
            if (location.pathname === `/otherpizza/${cData?.id}/${cData?.productCode}`) {
                handleCurrentEdit();
                setShowSidebar(false);
            } else {
                handleRedirectToEdit(`/otherpizza/${cData?.id}/${cData?.productCode}`);
                setShowSidebar(false);
            }
        }
    };

    // isEmptyObject
    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => { }, [payloadEdit]);
    return (
        <div className="row m-0 px-1 py-2 list-item">
            {/* Title */}
            <div className="col-md-9 col-8 text-start mb-1 productTitle">
                <span>{cData?.productName}</span>
            </div>
            {/* Price */}
            <div className="col-md-3 col-4 text-end mb-1">
                <span style={{ fontSize: "0.9rem" }}>$ {cData?.amount}</span>
            </div>
            {/* Quantity */}
            <div className="col-lg-12 text-start mb-1 cartQuantity">
                <p>Quantity :</p> <span className="mx-2">{cData?.quantity}</span>
            </div>
            {/* Pizza Size */}
            {cData?.config?.sidesSize && (
                <>
                    <div className="w-100 d-flex mb-2 text-start main-cartPizzaSize">
                        <p className="">Size : </p>
                        <span className="mx-2">
                            {cData?.config?.sidesSize ? cData?.config?.sidesSize : ""}
                        </span>
                    </div>
                    {cData?.comments !== "" && (
                        <div className="w-100 mb-2 main-cartPizzaSize">
                            <p className="d-inline">Comments : </p>
                            <span className="d-inline">{cData?.comments}</span>
                        </div>
                    )}
                </>
            )}
            {cData?.pizzaSize && (
                <div className="w-100 d-flex mb-2 text-start main-cartPizzaSize">
                    <p className="">Size : </p>
                    <span className="mx-2">
                        {cData?.pizzaSize !== "" ? cData?.pizzaSize : ""}
                    </span>
                </div>
            )}
            {cData?.pizzaPrice && (
                <div className="w-100 d-flex mb-2 text-start main-cartPizzaSize">
                    <p className="">Pizza Price : </p>
                    <span className="mx-2">
                        $ {cData?.pizzaPrice !== "" ? cData?.pizzaPrice : ""}
                    </span>
                </div>
            )}

            {cData?.config?.pizza &&
                cData?.config?.pizza.length > 0 &&
                cData?.config?.pizza.map((data, index) => {
                    return (
                        <div
                            className="d-flex justify-content-start flex-column selectedPizza"
                            key={index}
                        >
                            <h4 className="mb-1">
                                {cData?.productType === "custom_pizza"
                                    ? "Pizza"
                                    : "Pizza " + (index + 1)}
                            </h4>
                            {data?.crust && isEmptyObject(data?.crust) === false && (
                                <div className="mb-1">
                                    <p>Crust :</p>
                                    <span>{data?.crust?.crustName}</span>
                                </div>
                            )}
                            {data?.cheese && isEmptyObject(data?.cheese) === false && (
                                <div className="mb-1">
                                    <p>Cheese :</p>
                                    <span>{data?.cheese?.cheeseName}</span>
                                </div>
                            )}
                            {data?.specialBases &&
                                isEmptyObject(data?.specialBases) === false && (
                                    <div className="mb-1">
                                        <p>Specialbases :</p>
                                        <span>{data?.specialBases?.specialbaseName}</span>
                                    </div>
                                )}
                            {data?.spicy && isEmptyObject(data?.spicy) === false && (
                                <div className="mb-1">
                                    <p>Spicy :</p>
                                    <span>{data?.spicy?.spicy}</span>
                                </div>
                            )}
                            {data?.sauce && isEmptyObject(data?.sauce) === false && (
                                <div className="mb-1">
                                    <p>Sauce :</p>
                                    <span>{data?.sauce?.sauce}</span>
                                </div>
                            )}
                            {data?.cook && isEmptyObject(data?.cook) === false && (
                                <div className="mb-1">
                                    <p>Cook :</p>
                                    <span>{data?.cook?.cook}</span>
                                </div>
                            )}
                            <div className="mb-1">
                                {/* Count As Two */}
                                {data?.toppings?.countAsTwoToppings.length > 0 && (
                                    <div className="mb-1">
                                        <p>Toppings (Count 2) : </p>
                                        {data?.toppings?.countAsTwoToppings?.map((data, index) => {
                                            return (
                                                <span className="mx-1">
                                                    {data?.toppingsName} (
                                                    {data?.toppingsPlacement === "whole" && "W"}
                                                    {data?.toppingsPlacement === "lefthalf" && "L"}
                                                    {data?.toppingsPlacement === "righthalf" && "R"}
                                                    {data?.toppingsPlacement === "1/4" && "1/4"}),
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                                {/* Count As One */}
                                {data?.toppings?.countAsOneToppings?.length > 0 && (
                                    <div className="mb-1">
                                        <p>Toppings (Count 1) : </p>
                                        {data?.toppings?.countAsOneToppings?.map((data, index) => {
                                            return (
                                                <span className="mx-1">
                                                    {data?.toppingsName} (
                                                    {data?.toppingsPlacement === "whole" && "W"}
                                                    {data?.toppingsPlacement === "lefthalf" && "L"}
                                                    {data?.toppingsPlacement === "righthalf" && "R"}
                                                    {data?.toppingsPlacement === "1/4" && "1/4"}),
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                                {/* Free Toppings */}
                                {data?.toppings?.isAllIndiansTps ?
                                    <div>
                                        <p>Indian Style + Coriander</p>
                                    </div>
                                    : data?.toppings?.freeToppings?.length > 0 && (
                                        <div>
                                            <p>Indian Style Toppings: </p>
                                            {data?.toppings?.freeToppings?.map((data, index) => {
                                                return (
                                                    <span className="mx-1">
                                                        {data?.toppingsName} (
                                                        {data?.toppingsPlacement === "whole" && "W"}
                                                        {data?.toppingsPlacement === "lefthalf" && "L"}
                                                        {data?.toppingsPlacement === "righthalf" && "R"}
                                                        {data?.toppingsPlacement === "1/4" && "1/4"}),
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    )}
                            </div>
                        </div>
                    );
                })}

            {/* Sides */}
            {cData?.config?.sides?.length > 0 && (
                <div className="w-100 d-flex justify-content-start flex-wrap align-items-center main-cartPizza mb-1">
                    <p>Sides :</p>
                    {console.log(cData?.config?.sides)}
                    {cData?.config?.sides?.map((data, index) => {
                        return (
                            <span>
                                {data?.sideName} ({data?.sideSize})
                                {cData?.config?.sides?.length === index + 1 ? "" : ","}
                            </span>
                        );
                    })}
                </div>
            )}
            {/* Dips */}
            {cData?.config?.dips?.length > 0 && (
                <div className="w-100 d-flex justify-content-start align-items-center flex-warp main-cartPizza mb-1">
                    <p>Dips: </p>
                    {cData?.config?.dips?.map((data, index) => {
                        console.log(cData?.config?.dips?.length, index);
                        return (
                            <span>
                                {data?.dipsName}
                                {cData?.config?.dips?.length === index + 1 ? "" : ","}
                            </span>
                        );
                    })}
                </div>
            )}
            {/* Drinks */}
            {cData?.config?.drinks?.length > 0 && (
                <div className="selectedPizza mb-1">
                    <p>Drinks: </p>
                    {cData?.config?.drinks?.map((data, index) => {
                        return (
                            <span>
                                {data?.drinksName}
                                {cData?.config?.drinks?.length === index + 1 ? "" : ","}
                            </span>
                        );
                    })}
                </div>
            )}

            {/* Edit & Delete */}
            <div className="col-lg-12 mt-1">
                <i
                    className="fa fa-trash deleteIcon"
                    aria-hidden="true"
                    onClick={handleDelete}
                ></i>
                {cData.productType === "special_pizza" ||
                    cData.productType === "custom_pizza" || cData.productType === "signature_pizza" || cData.productType === "other_pizza" ? (
                    <i
                        className="fa fa-edit mx-3 editIcon"
                        aria-hidden="true"
                        onClick={handleEdit}
                    ></i>
                ) : (
                    <i className="fa fa-edit editIcon d-none" aria-hidden="true"></i>
                )}
            </div>
        </div>
    );
}

export default CartList;
