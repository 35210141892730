import { IoMdCheckmarkCircleOutline } from "react-icons/io"

export const SpicySelector = ({ data, selectedSpicy, handleSpicy }) => {
    const isSelected = selectedSpicy === data.spicyCode;
    const containerStyle = {
        cursor: 'pointer',
    };

    return (
        <div
            className={`${isSelected ? 'selected-card-background-color selected-card-text-color' : 'card-background-color card-text-color'}  py-3 px-3 mb-3 rounded-3`}
            style={containerStyle}
            onClick={() => handleSpicy(data.spicyCode)}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div className="fs-6 d-flex align-items-center gap-2">
                    <input
                        type="radio"
                        className="form-check-input"
                        checked={isSelected}
                        readOnly
                    />
                    <p className="fs-6">
                        {`${data.spicy} ($${data.price})`}
                    </p>
                </div>
                <IoMdCheckmarkCircleOutline
                    color={isSelected ? '#90EE90' : 'transparent'}
                    size={25}
                />
            </div>
        </div>
    );
};