import Footer from "../../components/_main/Footer";
import Header from "../../components/_main/Header";
import Tabs from "../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { getToppings } from "../../services";
import OtherPizza from "../OtherPizza";

const OtherPizzaList = () => {
    const [toppingsData, setToppingsData] = useState(null);

    const toppings = async () => {
        try {
            const res = await getToppings();
            setToppingsData(res?.data)
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        toppings();
    }, [])

    return (
        <>
            <div style={{ position: "relative" }}>
                <Header />
                <div className="nav-margin"></div>
                <Tabs />
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb custom-breadcrumb mt-5">
                            <li className="breadcrumb-item" aria-current="page">Home</li>
                            <li className="breadcrumb-item active" aria-current="page">Other Pizzas</li>
                        </ol>
                    </nav>
                </div>
                <OtherPizza toppingsData={toppingsData} />
                <Footer />
            </div>
        </>
    )
}

export default OtherPizzaList;