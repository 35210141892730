import React, { useEffect, useState } from "react";
import { getOtherPizza } from "../services";
import CartFunction from "../components/cart";
import OtherPizzas from "../components/_main/OtherPizza/otherPizza";
import LoadingLayout from "../layouts/LoadingLayout";
import "../assets/styles/grid-cards.css";

function OtherPizza({ toppingsData }) {
    const [otherData, setOtherData] = useState();
    const [loading, setLoading] = useState(true);
    const cartFn = new CartFunction();

    const otherpizza = async () => {
        setLoading(true)
        await getOtherPizza()
            .then((res) => {
                setLoading(false)
                setOtherData(res.data);
            })
            .catch((err) => {
                console.log("Error From Get Other Pizza :", err);
                setLoading(false)
            });
    };

    useEffect(() => {
        otherpizza();
    }, []);

    return (
        <>
            {
                loading ? (<LoadingLayout />) : (
                    <div className="section">
                        <div className="container">
                            <div className="grid-container">
                                {otherData?.map((data) => {
                                    return <OtherPizzas data={data} key={data.code} toppingsData={toppingsData} />;
                                })}
                            </div >
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default OtherPizza;
