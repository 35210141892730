import { IoMdCheckmarkCircleOutline } from "react-icons/io"

export const SpicySelector = ({ data, Spicy, handleSpicy }) => {
    return (
        <div className={`${Spicy === data?.spicyCode ? 'selected-card-background-color selected-card-text-color' : 'card-background-color card-text-color'}  py-3 px-3 mb-3 rounded-3`} style={{ cursor: "pointer" }} onClick={() => handleSpicy(data?.spicyCode)}>
            <div className="d-flex justify-content-between align-items-center" >
                <div className="fs-6 d-flex align-items-center gap-2"><input type="radio" className="form-check-input" checked={Spicy === data?.spicyCode} /><p className="fs-6">{`${data?.spicy} ($ ${data?.price})`}</p></div>
                {Spicy === data?.spicyCode ? <IoMdCheckmarkCircleOutline color="#90EE90" size={25} /> : <IoMdCheckmarkCircleOutline color="transparent" size={25} />}
            </div>
        </div>
    )
}