import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import "../../../assets/styles/grid-cards.css";
import SignatureModel from "./SignatureModal";
import { getAllIngredients } from "../../../services";

function SignaturePizzas({ data, toppingsData }) {

    const [show, setShow] = useState(false);
    const [ingredients, setIngredients] = useState(null);
    const pizzaQuantityRef = useRef(null);
    const pizzaSizeRef = useRef(null);

    const handleClose = () => {
        setShow(false);
        pizzaSizeRef.current.value = data?.pizza_prices.filter((price) => price.price > 0)?.[0]?.shortcode;
        if (pizzaSizeRef.current) {
            pizzaQuantityRef.current.value = 1;
        }
    };

    const getIngredients = async () => {
        const res = await getAllIngredients();
        setIngredients(res?.data);
    };
    // Handle Sides - Add To Cart Button
    const handleSides = () => {
        setShow(true);
    };
    const handleLinkClick = (e) => {
        // Prevent navigation for specific elements
        if (
            e.target.tagName === "SELECT" ||
            e.target.tagName === "OPTION" ||
            e.target.classList.contains("addBtn") // Ensure it matches the ADD button
        ) {
            e.preventDefault();
            e.stopPropagation(); // Ensure it doesn't trigger other listeners
        }
    };

    useEffect(() => {
        getIngredients();
    }, []);


    useEffect(() => {
        if (pizzaSizeRef.current) {
            pizzaSizeRef.current.value = data?.pizza_prices.filter((price) => price.price > 0)?.[0]?.shortcode;
        }
    }, [data]);

    return (
        <div
            className="grid-card-outer"
            key={"special-grid-card-" + data?.code}
        >
            <Link to={`${"/signaturepizza"}/${data?.code}`} onClick={handleLinkClick}>
                <div className="grid-card-placeholder">
                    <div className="grid-top-container">
                        <img
                            src={data?.pizza_image}
                            alt={data?.pizza_name}
                            className="bestseller-placeholder"

                        />
                        {data?.dealType === "pickupdeal" && (
                            <div className="top-pizza-toggle">
                                <div className="tag_list">
                                    <span>{"Pickup Deal"}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="grid-card-detail">
                        <div className="pizzaTitleDiv">
                            <div className="pizzaTitle">
                                <h5 className="mb-1 text-decoration-none">
                                    {data?.pizza_name}
                                </h5>
                            </div>
                        </div>
                        <div className="pizzaSubTitleDiv">
                            <div className="pizzaSubTitle">
                                <p className="mb-1 text-decoration-none">
                                    {data?.pizza_subtitle ? data.pizza_subtitle : <>&nbsp;</>}
                                </p>
                            </div>
                        </div>
                        <div className="grid-card-pizzasize d-flex align-items-center flex-row-reverse gap-3">
                            <select className="select-input w-auto" ref={pizzaQuantityRef}>
                                {[...Array(10).keys()].map((num) => (
                                    <option key={num + 1} value={num + 1}>
                                        {num + 1}
                                    </option>
                                ))}
                            </select>
                            <select className="select-input w-75 pizzaPriceSelect" ref={pizzaSizeRef}>
                                {data?.pizza_prices
                                    ?.filter((price) => parseFloat(price.price) > 0)
                                    ?.map((price) => (
                                        <option value={price.shortcode} key={price.shortcode}>
                                            {price.size} - $ {price.price}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="btn-container">
                            <div className="grid-card-left">
                                <span className="addBtn fill-view-btn" onClick={handleSides}>
                                    ADD
                                </span>
                            </div>
                            <div className="grid-card-right">
                                <span className="view-btn">
                                    {/* <FaEdit /> */}
                                    Customize
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            {show && pizzaSizeRef.current && (
                <SignatureModel
                    show={show}
                    handleClose={handleClose}
                    data={data}
                    ing={ingredients}
                    toppingsData={toppingsData}
                    pizzaSize={pizzaSizeRef.current.value}
                    pizzaQuantity={pizzaQuantityRef.current.value}
                />
            )}
        </div>
    );
}

export default SignaturePizzas;
