import React from 'react'
import { CrustSelector } from './CrustSelector';
import { CrustTypeSelector } from './CrustTypeSelector';
import { CookSelector } from './CookSelector';
import { SpecialBasesSelector } from './SpecialBasesSelector';

function DoughSelector({ Ingredients, Crust, setCrust, CrustType, setCrustType, SpecialBases, setSpecialBases }) {
    return (
        <>
            {/* crust */}
            <div className="mt-3">
                <div className="">
                    <div className="">
                        <h2 className="mb-3 primary-text-color" id="headingTwo">
                            CRUST
                        </h2>
                        <div className="">
                            {Ingredients?.crust?.map((data, index) => {
                                return (
                                    <CrustSelector data={data} Crust={Crust} handleCrust={(payload) => setCrust(payload)} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* crust type */}
            <div className="mt-3">
                <div className="">
                    <div className="">
                        <h2 className="mb-3 primary-text-color" id="headingTwo">
                            CRUST TYPE
                        </h2>
                        <div className="">
                            {Ingredients?.crustType?.map((data, index) => {
                                return (
                                    <CrustTypeSelector data={data} CrustType={CrustType} handleCrustType={(payload) => setCrustType(payload)} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* cook */}
            <div className="mt-3">
                <div className="">
                    <div className="">
                        <h2 className="mb-3 primary-text-color" id="headingTwo">
                            SPECIAL BASES
                        </h2>
                        <div className="">
                            {Ingredients?.specialbases?.map((data, index) => {
                                return (
                                    <SpecialBasesSelector data={data} SpecialBases={SpecialBases} handleSpecialBases={(payload) => setSpecialBases(payload)} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DoughSelector