import Footer from "../../components/_main/Footer"
import Header from "../../components/_main/Header"
import Tabs from "../../components/Tabs/Tabs"
import DipsMenu from "../DipsMenu"

const Dips = () => {
    return (
        <>
            <Header />
            <div className="nav-margin"></div>
            <Tabs />
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb custom-breadcrumb mt-5">
                        <li className="breadcrumb-item" aria-current="page">Home</li>
                        <li className="breadcrumb-item active" aria-current="page">Dips</li>
                    </ol>
                </nav>
            </div>
            <DipsMenu />
            <Footer />
        </>
    )
}

export default Dips;