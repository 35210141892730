import React from 'react'
import "../../../assets/styles/grid-cards.css";
import SignaturePizzas from '../../_main/SignaturePizza/signturePizza';
import CartFunction from '../../cart';
import pizzaLeft from "../../../assets/images/pizza-left.png";
import pizzaRight from "../../../assets/images/pizza-right.png";
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';

function SignatureSelector({ signaturePizzas, topping }) {
    const cartFn = new CartFunction();
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="section-header" >
                        <h3 className="section-title" >
                            {/* <img src={pizzaLeft} alt="Left Pizza Icon" /> */}
                            Signature Pizzas
                            {/* <img src={pizzaRight} alt="Right Pizza Icon" /> */}
                        </h3>
                        <Link className="more-link" to={`/signaturepizza`}>
                            View All
                            <FaChevronRight />
                        </Link>
                    </div>
                    <div className="grid-container">
                        {signaturePizzas?.map((data) => {
                            return <SignaturePizzas data={data} key={data.sideCode} cartFn={cartFn} toppingsData={topping} />;
                        })}
                    </div>
                </div>
            </div >
        </>
    )
}

export default SignatureSelector