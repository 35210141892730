import React, { useContext, useEffect, useState } from "react";
import Dips from "../components/_main/Dips/Dips";
import { getDips } from "../services";
import CartFunction from "../components/cart";
import CartList from "../components/_main/Cart/CartList";
import GlobalContext from "../context/GlobalContext";
import OrderSummary from "../components/_main/Cart/OrderSummary";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingLayout from "../layouts/LoadingLayout";

function DipsMenu() {
    const [dipsData, setDipsData] = useState();
    const [reset, setReset] = useState(false);
    const [loading, setLoading] = useState(true);
    const cartFn = new CartFunction();

    const globalctx = useContext(GlobalContext);
    const [cart, setCart] = globalctx.cart;
    const [payloadEdit, setPayloadEdit] = globalctx.productEdit;
    const [isAuthenticated, setIsAuthenticated] = globalctx.auth;

    const { user } = useSelector((state) => state);

    const location = useLocation();
    const navigate = useNavigate();

    const dips = async () => {
        setLoading(true)
        await getDips()
            .then((res) => {
                setDipsData(res.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log("Error From Dips Menu : ", err);
                setLoading(false)
            });
    };

    // Handle Place Order
    const handlePlaceOrder = async () => {
        if (cart?.product?.length > 0) {
            if (isAuthenticated && user !== null) {
                navigate("/checkout-page");
            } else {
                localStorage.setItem("redirectTo", location?.pathname);
                navigate("/login");
            }
        } else {
            toast.error("Cart is Empty...");
        }
    };

    // Reset Controls
    const resetControls = () => {
        // Reset All Fields
        setReset(true);
        setTimeout(() => {
            setReset(false);
        }, 200);
    };

    useEffect(() => {
        dips();
    }, []);
    return (
        <>
            {loading ? (<LoadingLayout />) : (
                <div className="section">
                    <div className="container">
                        <div className="grid-container">
                            {dipsData?.map((data) => {
                                return <Dips key={data.dipsCode} data={data} cartFn={cartFn} />;
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DipsMenu;
