import { Route, Routes, } from "react-router-dom";
import CreateYourOwn from "../pages/CreateYourOwn";
import Home from "../pages/Home";
import SpecialMenu from "../pages/SpecialMenu";
import Cart from "../pages/Cart";
import Success from "../pages/Payment/Success";
import MyAccount from "../pages/Auth/MyAccount";
import SelectedDrink from "../components/_main/DrinksOld/SelectedDrink";
import CheckoutPage from "../pages/CheckoutPage";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import SpecialPizzaList from "../pages/SpecialPizza/SpecialPizza";
import SignaturePizzaList from "../pages/SignaturePizza/SignaturePizza";
import Sides from "../pages/Sides/Sides";
import Drinks from "../pages/Drinks/Drinks";
import Dips from "../pages/Dips/Dips";
import Sidebar from "../layouts/Sidebar";
import ScrollToTop from "../ScrollToTop";
import CreatePizza from "../pages/Createyourown/Create";
import EditCreatePizza from "../pages/Createyourown/EditCreatePizza";
import Signature from "../pages/SignaturePizza/Signature";
import EditSignature from "../pages/SignaturePizza/EditSignaturePizza";
import OtherPizzaList from "../pages/OtherPizza/OtherPizza";
import Other from "../pages/OtherPizza/Other";
import EditOther from "../pages/OtherPizza/EditOtherPizza";
import Stores from "../pages/Stores";
import SpecialOfferList from "../pages/SpecialOffer/SpecialOfferList";
import SpecialOffer from "../pages/SpecialOffer/SpecialOffer";
import EditSpecialOffer from "../pages/SpecialOffer/EditSpecialOffer";
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsCondtions from '../pages/TermsCondtions';
import CustomizeDrinks from "../components/_main/Drinks/CustomizeDrinks";
import Menu from "../pages/Menu";
import RegistrationPage from "../pages/Auth/RegistrationPage";
import LoginPage from "../pages/Auth/LoginPage";
import ForgetPassword from "../components/_main/Auth/ForgetPassword";
import UpdatePassword from "../components/_main/Auth/UpdatePassword";

const AllRoutes = () => {
    return (
        <>
            <Sidebar />
            <ScrollToTop />
            <Routes>
                <Route exact index path="/" Component={Home} />
                {/* <Route exact index path="/home-fullscreen" Component={HomeDemo} /> */}
                <Route exact path="/:ptype" Component={Home} />
                <Route exact path="/login" Component={LoginPage} />
                <Route exact path="/registration" Component={RegistrationPage} />
                <Route exact path="/forget-password" Component={ForgetPassword} />
                <Route exact path="/update-password/:token" Component={UpdatePassword} />
                <Route exact path="/menu" Component={Menu} />
                <Route exact path="/create-your-own-old/" Component={CreateYourOwn} />
                <Route exact path="/create-your-own/" Component={CreatePizza} />
                <Route exact path="/create-your-own/:uid" Component={EditCreatePizza} />
                <Route exact path="/special-pizza-old" Component={SpecialPizzaList} />
                <Route exact path="/customize-drink-old/:did" Component={SelectedDrink} />
                <Route exact path="/customize-drink/:did" Component={CustomizeDrinks} />
                <Route exact path="/special-pizza-old/:sid" Component={SpecialMenu} />
                <Route exact path="/signaturepizza" Component={SignaturePizzaList} />
                {/* <Route exact path="/signaturepizza/:sid" Component={SignatureMenu} /> */}
                <Route exact path="/signaturepizza/:sid" Component={Signature} />
                <Route exact path="/signaturepizza/:pid/:sid" Component={EditSignature} />
                {/* Other Pizzas */}
                <Route exact path="/otherpizza" Component={OtherPizzaList} />
                <Route exact path="/otherpizza/:sid" Component={Other} />
                <Route exact path="/otherpizza/:pid/:sid" Component={EditOther} />
                {/* Deals */}
                <Route exact path="/specialoffer" Component={SpecialOfferList} />
                <Route exact path="/specialoffer/:sid" Component={SpecialOffer} />
                <Route exact path="/specialoffer/:pid/:sid" Component={EditSpecialOffer} />
                {/*  */}
                <Route exact path="/dips" Component={Dips} />
                <Route exact path="/drinks" Component={Drinks} />
                <Route exact path="/sides" Component={Sides} />
                <Route exact path="/addtocart" Component={Cart} />
                {/* <Route exact path="/address-details" Component={AddressDetails} /> */}
                <Route exact path="/order/verify" Component={Success} />
                <Route exact path="/my-account" Component={MyAccount} />
                <Route exact path="/checkout-page" Component={CheckoutPage} />
                <Route exact path="/stores" Component={Stores} />
                <Route exact path="/about-us" Component={AboutUs} />
                <Route exact path="/contact-us" Component={ContactUs} />

                <Route exact path="/privacy-policy" Component={PrivacyPolicy} />
                <Route exact path="/terms-conditions" Component={TermsCondtions} />
            </Routes>
        </>
    );
};

export default AllRoutes;
