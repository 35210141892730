import React, { useEffect, useState } from 'react'
import { specialIngredients } from '../services';
import { toast } from 'react-toastify';
import LoadingLayout from '../layouts/LoadingLayout';
import SpecialOffers from '../components/_main/SpecialOffer/SpecialOffers';

function SpecialOffer() {
    const [specialOfferData, setSpecialOfferData] = useState(null);
    const [loading, setLoading] = useState(false);

    const specialOffer = async () => {
        setLoading(true);
        await specialIngredients().then((res) => {
            setLoading(false);
            if (res?.data && res?.data?.length > 0) {
                const activeOffers = res?.data.filter(isLimitedOfferActive);
                setSpecialOfferData(activeOffers);
            }
        }).catch((err) => {
            toast.error("Error From Get Other Pizza :", err);
            setLoading(false)
        })
    }
    // Filter All Reacords From Deals API 
    const isLimitedOfferActive = (offer) => {
        if (offer.limitedOffer === 1) {
            const currentDate = new Date();
            const startDate = new Date(offer.limitedOfferStartDate);
            const endDate = new Date(offer.limitedOfferEndDate);

            if (currentDate >= startDate && currentDate <= endDate) {
                return true;
            } else {
                return false
            }
        }
        return true;
    };

    useEffect(() => {
        specialOffer();
    }, [])

    return (
        <>
            {
                loading ? (
                    <LoadingLayout />
                ) : (
                    <div className="section">
                        <div className="container">
                            <div className="grid-container">
                                {specialOfferData?.map((data) => {
                                    return (
                                        <SpecialOffers key={data.code} data={data} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SpecialOffer