import React from 'react'

import pizzaLeft from "../../../assets/images/pizza-left.png";
import pizzaRight from "../../../assets/images/pizza-right.png";
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';
import Dips from '../../_main/Dips/Dips';
import CartFunction from '../../cart';

function DipsSelector({ dips }) {
    const cartFn = new CartFunction();
    return (
        <>
            <div className="section">
                <div className="container">
                    <div className="section-header" >
                        <h3 className="section-title" >
                            {/* <img src={pizzaLeft} alt="Left Pizza Icon" /> */}
                            Dips
                            {/* <img src={pizzaRight} alt="Right Pizza Icon" /> */}
                        </h3>
                        <Link className="more-link" to={`/dips`}>
                            View All
                            <FaChevronRight />
                        </Link>
                    </div>
                    <div className="grid-container">
                        {dips?.map((data) => {
                            return <Dips key={data.dipsCode} data={data} cartFn={cartFn} />;
                        })}
                    </div>
                </div>
            </div >
        </>
    )
}

export default DipsSelector