import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        localStorage.getItem("token") ? true : false
    );
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));
    const [currentStoreCode, setCurrentStoreCode] = useState(localStorage.getItem("currentStoreCode"));
    // const [currentCity, setCurrentCity] = useState(JSON.parse(localStorage.getItem("currentCity")));
    // const [currentStore, setCurrentStore] = useState(JSON.parse(localStorage.getItem("currentStore")));
    const [currentLatitude, setCurrentLatitude] = useState(localStorage.getItem("currentLatitude"));
    const [currentLogitude, setCurrentLogitude] = useState(localStorage.getItem("currentLogitude"));

    const [currentCity, setCurrentCity] = useState(() => {
        const storedCity = localStorage.getItem("currentCity");
        return storedCity ? JSON.parse(storedCity) : null;
    });
    const [currentStore, setCurrentStore] = useState(() => {
        const storedStore = localStorage.getItem("currentStore");
        return storedStore ? JSON.parse(storedStore) : null;
    });


    const [scrollToSignature, setScrollToSignature] = useState(false);
    const [selectedType, setSelectedType] = useState(localStorage.getItem("selectedType"));
    const [payloadEdit, setPayloadEdit] = useState();
    const [showStorePopup, setShowStorePopup] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [url, setUrl] = useState(null);
    const [productType, setProductType] = useState();
    const [settings, setSettings] = useState();
    const [regUser, setRegUser] = useState(
        JSON.parse(localStorage.getItem("registeredUser"))
    );
    const [reset, setReset] = useState(false);

    return (
        <GlobalContext.Provider
            value={{
                auth: [isAuthenticated, setIsAuthenticated],
                user: [user, setUser],
                regUser: [regUser, setRegUser],
                cart: [cart, setCart],
                productEdit: [payloadEdit, setPayloadEdit],
                urlPath: [url, setUrl],
                productType: [productType, setProductType],
                settings: [settings, setSettings],
                reset: [reset, setReset],
                sidebar: [showSidebar, setShowSidebar],
                currentStoreCode: [currentStoreCode, setCurrentStoreCode],
                currentCity: [currentCity, setCurrentCity],
                currentStore: [currentStore, setCurrentStore],
                scrollToSignature: [scrollToSignature, setScrollToSignature],
                selectedType: [selectedType, setSelectedType],
                showStorePopup: [showStorePopup, setShowStorePopup],
                currentLatitude: [currentLatitude, setCurrentLatitude],
                currentLogitude: [currentLogitude, setCurrentLogitude],
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
