import React from "react";
import playStoreImg from '../../assets/images/playstore.svg';
import appStoreImg from '../../assets/images/appstore.svg';
import DownloadApp from "../../assets/images/download/download.png";
import "./DownloadApps.css"; // Custom CSS for additional styling

const DownloadApps = ({ isdemo }) => {
    return (
        <div className="section py-5 download-background">
            <div className="download-section text-center">
                <div className={isdemo ? "container-fluid" : 'container'}>
                    <div className="row align-items-center">
                        {/* Left Column: Image */}
                        <div className="col-md-5 text-center mb-4 mb-md-0">
                            <img
                                src={DownloadApp}
                                alt="Download App"
                                className="img-fluid download-image"
                            />
                        </div>

                        {/* Right Column: Content */}
                        <div className="col-md-7">
                            <div className="section-header text-center mb-3">
                                <h3 className="section-title">Download Our Apps</h3>
                            </div>
                            <p className="mt-3 mb-4 description">
                                Get our app to enjoy exclusive features and seamless access to
                                our services.
                            </p>
                            <div className="app-buttons d-flex justify-content-center gap-4">
                                <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={appStoreImg}
                                        alt="App Store"
                                        className="app-store-btn img-fluid"
                                    />
                                </a>
                                <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={playStoreImg}
                                        alt="Google Play"
                                        className="play-store-btn img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadApps;
