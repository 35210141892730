import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import { CgMail, CgPhone } from "react-icons/cg";
import GlobalContext from "../../context/GlobalContext";
import appLogo from "../../assets/images/logo.png";

const Footer = ({ isdemo }) => {
    const globalCtx = useContext(GlobalContext);
    const [isAuthenticated, setIsAuthenticated] = globalCtx.auth;
    return (
        <footer className="main-footer new-block pt-4">
            <div className={isdemo ? "container-fluid" : 'container'}>
                <div className="row text-light ">
                    <div className="col-12 col-md-4 sm-center mb-3">
                        <div className="logo">
                            <Link to={"/"}>
                                <img src={appLogo} alt="logo" className="img-fluid" style={{ width: "65px" }} />
                            </Link>
                        </div>
                        <p className="my-3 lh-sm">At <span className="main fw-bold">Mr. Singhs Pizza</span>, we craft delicious, handmade pizzas with the freshest ingredients to bring you a slice of happiness in every bite.</p>
                    </div>
                    <div className="col-12 col-md-8 mb-3">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="row">
                                    <div className="col col-sm-6">
                                        <h5 className="main fs-6 fw-bold">Quick Links</h5>
                                        <div className="py-3 d-flex flex-column gap-2">
                                            <Link to={"/"} className="text-decoration-none"><span className="">Home</span></Link>
                                            <Link to={"/stores"} className="text-decoration-none"><span className="">Stores</span></Link>
                                            <Link to={"/about-us"} className="text-decoration-none"><span className="">About</span></Link>
                                            <Link to={"/contact-us"} className="text-decoration-none"><span className="">Contact</span></Link>
                                        </div>
                                    </div>

                                    <div className="col col-sm-6">
                                        <h5 className="main fs-6 fw-bold">Useful Links</h5>
                                        <div className="py-3 d-flex flex-column gap-2">
                                            <Link to={"/terms-conditions"} className="text-decoration-none"><span className="">Terms</span></Link>
                                            <Link to={"/privacy-policy"} className="text-decoration-none"><span className="">Privacy</span></Link>
                                            <Link to={isAuthenticated ? "/my-account" : '/login'} className="text-decoration-none">
                                                <span className="">{isAuthenticated ? 'My Account' : 'Login'}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <h5 className="main fs-6 fw-bold">Contact Info</h5>
                                <div className="py-3 d-flex flex-column gap-3 w-100">
                                    <div className="d-flex justify-content-start  align-items-center gap-1">
                                        <Link to="mailto:support@mrsinghspizza.ca?subject=Contact" className="text-decoration-none" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                            <CgMail size={18} className="me-2" />
                                            <span>support@mrsinghspizza.ca</span>
                                        </Link>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center gap-1" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                        <Link to="tel:+19055004000" className="text-decoration-none" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                            <CgPhone size={16} className="me-2" />
                                            <span className="">+1 905-500-4000</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copy-right">
                    <p>
                        2024 CopyRight. All Rights Reserved. Designed by <Link to={`https://www.exter.ca/`}> EXTER</Link>
                    </p>
                    <ul className="social-nav">
                        <li>
                            <Link
                                target="_blank"
                                to="https://www.instagram.com/mrsinghspizzacanada/?next=https%3A%2F%2Fwww.instagram.com%2Fdirect%2Finbox%2F%3F__coig_login%3D1"
                            >
                                <FiInstagram />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.facebook.com/p/Mr-Singhs-Pizza-Canada-100091853637906/" target="_blank">
                                <FiFacebook />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://ca.linkedin.com/company/mrsinghspizza" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.youtube.com/@Mr.SinghsPizza/featured" target="_blank">
                                <FiYoutube />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
