import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomizeDrinkDropdown from './CustomizeDrinkDropdown';
import LoadingLayout from '../../../layouts/LoadingLayout';
import SPNotFound from '../../../layouts/SPNotFound';
import ResponsiveCart from '../Cart/ResponsiveCart';
import { v4 as uuidv4 } from "uuid";
import { FaEye, FaMinus, FaPlus } from 'react-icons/fa6';
import DrinksViewSelectionModal from './DrinksViewSelectionModal';

function CustomizeDrinkContent({ loading, drinkData, settings, user, cartFn, setCart, currentStoreCode, setShowStorePopup }) {
    const [selectedDrinksTypeArr, setSelectedDrinksTypeArr] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [isTranslate, setIsTranslate] = useState(false);
    const [translateYVal, setTranslateYVal] = useState(null);
    const [drinksQuantity, setDrinksQuantity] = useState(1);
    const [showDrink, setShowDrinks] = useState(1);

    // 
    const [viewSelection, setViewSelection] = useState(false);

    const navigate = useNavigate();

    const toggleDrinks = (index) => {
        console.log(index);
        setShowDrinks(showDrink === index ? 1 : index);
        setTimeout(() => window.dispatchEvent(new Event('resize')), 8);
    }
    // Handle Multiple Drinks Dropdown
    const spSelection = [];
    for (let i = 1; i <= drinkData?.drinksCount; i++) {
        spSelection.push(
            <CustomizeDrinkDropdown
                key={i}
                count={i}
                drinksType={drinkData?.drinkType}
                setSelectedDrinksTypeArr={setSelectedDrinksTypeArr}
                selectedDrinksTypeArr={selectedDrinksTypeArr}
                drinkData={drinkData}
                toggleDrinks={toggleDrinks}
                showDrink={showDrink}
                setShowDrinks={setShowDrinks}
            />
        );
    }

    const handleAddToCart = () => {
        if (!currentStoreCode) {
            setShowStorePopup(true)
            return false;
        }
        // For Comments Logic - Create String with comma separated
        const drinksComments = selectedDrinksTypeArr.join(", ");

        // Cart Object
        const payload = {
            id: uuidv4(),
            customerCode: user?.data?.customerCode,
            cashierCode: "#NA",
            productCode: drinkData.softdrinkCode,
            productName: drinkData.softDrinksName,
            productType: "drinks",
            config: {
                type: "",
            },
            price: drinkData.price,
            quantity: drinksQuantity,
            amount: (Number(drinkData?.price) * Number(drinksQuantity)).toFixed(2),
            taxPer: 0,
            pizzaSize: "",
            comments: drinksComments,
        };

        if (payload) {
            let ct = JSON.parse(localStorage.getItem("cart"));
            ct.product.push(payload);
            const cartProduct = ct.product;
            cartFn.addCart(cartProduct, setCart, false, settings);
            navigate('/');
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth <= 766) return;
            const scrollY = window.scrollY;
            const rightSideDiv = document.querySelector('.right-side-internal-div');
            const footer = document.querySelector('.main-footer');

            if (!rightSideDiv || !footer) return;

            const rightDivTopOffset = rightSideDiv.getBoundingClientRect().top + scrollY;
            const rightDivBottomOffset = rightSideDiv.getBoundingClientRect().bottom + scrollY;
            const footerOffset = footer.offsetTop;
            const rightDivHeight = rightSideDiv.offsetHeight;

            const isBottomTouch = rightDivBottomOffset + 20 >= footerOffset;

            if (scrollY >= 150) {
                if (!isBottomTouch) {
                    setIsFixed(true);
                    setIsTranslate(false);
                    setTranslateYVal(null);
                } else {
                    if (scrollY + 80 >= rightDivTopOffset) {
                        setIsFixed(false);
                        setIsTranslate(true);
                        setTranslateYVal(footerOffset - 250 - rightDivHeight);
                    } else {
                        setIsFixed(true);
                        setIsTranslate(false);
                        setTranslateYVal(null);
                    }
                }
            } else {
                if (!isTranslate) {
                    setIsFixed(false);
                    setIsTranslate(false);
                    setTranslateYVal(null);
                }
            }
        };

        const handleResize = () => {
            handleScroll(); // Recalculate on resize or accordion toggle
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [isFixed, isTranslate, translateYVal, showDrink]);



    return (
        <>
            {loading ? <LoadingLayout /> :
                <>
                    {drinkData ?
                        <>
                            <div className="new-block" id="create-your-own-new" >
                                <section className="special-offers-sec new-block primary-background-color">
                                    <div className="container">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb custom-breadcrumb mt-5">
                                                <li className="breadcrumb-item" aria-current="page">Home</li>
                                                <li className="breadcrumb-item active" aria-current="page">Drinks</li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <div className="container">
                                        <div className="mainContainer" >
                                            {/* Left side */}
                                            <div className=" p-3">
                                                <p className="fs-1 fw-bold mb-4">{drinkData?.softDrinksName}</p>
                                                <p className="fs-3 fw-bold">CUSTOMIZE</p>
                                                <p className="mt-3 mb-3 fs-6">Customize drinks.</p>

                                                <div className="right-side-div p-0 w-100 d-lg-none d-block" style={{ position: 'relative !important' }}>
                                                    <div className={`p-3 card-background-color card-text-color ${isFixed ? 'fixed' : ''}`} style={{
                                                        transform: isTranslate ? `translateY(${translateYVal}px)` : 'none',
                                                    }}>
                                                        <div className="row justify-content-start align-content-center p-0 m-0">
                                                            <div className="col-auto p-0 m-0 rounded-3 text-center">
                                                                <img
                                                                    className="pizzaImageBorderSM"
                                                                    src={drinkData?.image}
                                                                    alt="drink-icon"
                                                                />
                                                            </div>
                                                            <div className="col-7 p-0 m-0">
                                                                <div className="d-flex flex-column justify-content-center " style={{ padding: '0px 10px' }}>
                                                                    <p className="lh-sm fw-bold text-start my-1 pizzaPriceSm">
                                                                        $ {(Number(drinkData?.price) * Number(drinksQuantity)).toFixed(2)}
                                                                    </p>
                                                                    <div className="d-flex justify-content-start align-items-center my-1" style={{ userSelect: 'none' }}>
                                                                        <button
                                                                            disabled={drinksQuantity <= 1}
                                                                            onClick={() => setDrinksQuantity(prev => prev - 1)}
                                                                            className="btn btn-secondary rounded-circle pizzaQtyButtonSm"
                                                                            aria-label="Decrease Quantity"
                                                                        >
                                                                            <FaMinus className="pizzaQtyButtonSpanSm fs-6" />
                                                                        </button>
                                                                        <p className="lh-sm fs-5 fw-bold mx-2">{drinksQuantity}</p>
                                                                        <button
                                                                            disabled={drinksQuantity >= 10}
                                                                            onClick={() => setDrinksQuantity(prev => prev + 1)}
                                                                            className="btn btn-secondary rounded-circle pizzaQtyButtonSm"
                                                                            aria-label="Increase Quantity"
                                                                        >
                                                                            <FaPlus className="pizzaQtyButtonSpanSm fs-6" />
                                                                        </button>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start">
                                                                        <div className="d-flex me-2 justify-content-start py-2">
                                                                            <button
                                                                                onClick={handleAddToCart}
                                                                                className="btn pizza-card-btn-background-color pizza-card-btn-text-color fw-bold pizzaAddToCardBtnSm"
                                                                            >
                                                                                ADD TO CART
                                                                            </button>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start py-2">
                                                                            <button
                                                                                onClick={() => setViewSelection(true)}
                                                                                className="btn pizza-view-selection-btn-background-color pizza-card-btn-text-color fw-bold pizzaAddToCardBtnSm"
                                                                            >
                                                                                <FaEye />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {spSelection}
                                            </div>

                                            {/* Right side */}
                                            <div className="right-side-div p-3 d-lg-block d-none" style={{ position: 'relative !important' }}>
                                                <div className={`p-3 right-side-internal-div card-background-color ${isFixed ? 'fixed' : ''}`} style={{
                                                    transform: isTranslate ? `translateY(${translateYVal}px)` : 'none',
                                                }}>
                                                    <div className="px-3 row">
                                                        <div className="col-lg-6 p-3 rounded-3">
                                                            <img className="pizzaImageBorder" src={drinkData?.image} alt="drink-icon" />
                                                        </div>
                                                        <div className="col-lg-6 p-4">
                                                            <div className="d-flex flex-column py-4">
                                                                <p className="lh-sm fs-1 fw-bold text-center text-lg-start">
                                                                    $ {(Number(drinkData?.price) * Number(drinksQuantity)).toFixed(2)}
                                                                </p>
                                                                <div className=" d-flex justify-content-center  justify-content-lg-start align-items-center mt-3" style={{ userSelect: 'none' }}>
                                                                    <button disabled={drinksQuantity <= 1} onClick={() => setDrinksQuantity(prev => prev - 1)} className="btn btn-secondary rounded-circle pizzaQtyButton" ><FaMinus className="pizzaQtyButtonSpan" /></button>
                                                                    <p className="lh-sm fs-4 fw-bold mx-2">{drinksQuantity}</p>
                                                                    <button disabled={drinksQuantity >= 10} className="btn btn-secondary rounded-circle pizzaQtyButton" onClick={() => setDrinksQuantity(prev => prev + 1)}  ><FaPlus className="pizzaQtyButtonSpan" /></button>
                                                                </div>
                                                                <div className="d-flex justify-content-center justify-content-lg-start">
                                                                    <button
                                                                        onClick={() => handleAddToCart()}
                                                                        className="mt-3 btn pizza-card-btn-background-color pizza-card-btn-text-color fs-5 fw-bold mt-4"
                                                                    >
                                                                        Add to Cart
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="scrollable-content">
                                                            <div className="col-12">
                                                                {selectedDrinksTypeArr && selectedDrinksTypeArr.length > 0 && (
                                                                    <div className="border-top pizza-card-border-color mt-4 py-3">
                                                                        <p className='fs-6 fw-bold  mx-1'>Drinks Type: </p>
                                                                        <div className="d-flex flex-wrap">
                                                                            {selectedDrinksTypeArr.map((data, index) => (
                                                                                <p className="lh-sm fs-6 mt-2 mb-0 mx-1" key={index}>
                                                                                    {data}
                                                                                    {index < selectedDrinksTypeArr.length - 1 && ','}
                                                                                </p>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <ResponsiveCart handleCart={handleAddToCart} totalPrice={drinkData?.price} section={'ADD TO CART'} />
                            <DrinksViewSelectionModal
                                viewSelection={viewSelection}
                                setViewSelection={setViewSelection}
                                selectedDrinksTypeArr={selectedDrinksTypeArr}
                            />
                        </> : <SPNotFound />
                    }
                </>
            }
        </>
    );
}

export default CustomizeDrinkContent;