import React from "react";

function SearchBox({ searchQuery, setSearchQuery, onClear }) {
    return (
        <div className="search-container mb-3 mt-1 rounded-5 overflow-hidden">
            <input
                type="text"
                placeholder="Search by store or city"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className=""
            />
            <button className="clear-btn" onClick={onClear}>
                <i className="fas fa-times"></i>
            </button>
        </div>
    );
}

export default SearchBox;
