import { useContext, useEffect, useState } from "react";
import CartList from "../components/_main/Cart/CartList";
import OrderSummary from "../components/_main/Cart/OrderSummary";
import GlobalContext from "../context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";

const Sidebar = () => {
    const globalCtx = useContext(GlobalContext);
    const [cart, setCart] = globalCtx.cart;
    const [showSidebar, setShowSidebar] = globalCtx.sidebar;
    const [payloadEdit, setPayloadEdit] = globalCtx.productEdit;
    const [isAuthenticated, setIsAuthenticated] = globalCtx.auth;
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state);


    const handlePlaceOrder = async () => {
        if (cart?.product?.length > 0) {
            if (isAuthenticated && user !== null) {
                setShowSidebar(false)
                navigate("/checkout-page");
            } else {
                localStorage.setItem("redirectTo", location?.pathname);
                setShowSidebar(false)
                navigate("/login");
            }
        } else {
            toast.error("Cart is Empty...");
        }
    };


    useEffect(() => {
        if (showSidebar) {
            // Disable scrolling on the body when sidebar is open
            document.body.classList.add('no-scroll');
        } else {
            // Enable scrolling on the body when sidebar is closed
            document.body.classList.remove('no-scroll');
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showSidebar]);



    return (
        <div id="sidebar">
            <div className="overlay" style={{ display: `${showSidebar ? "block" : "none"}` }} onClick={() => setShowSidebar(false)}></div>
            <div className={`sidebar position-fixed right-0 top-0 bottom-0  pb-5 BgsecondaryBlackColor ${showSidebar ? "show" : "hide"}`}>
                <div className="cartlist w-100 bgPrimaryBlackColor primaryWhiteColor" style={{ height: "76%", overflowY: "auto" }}>
                    <div style={{ position: "relative" }} >
                        <h3 className="p-2 text-left orderTitle">Your Cart</h3>
                        <span
                            onClick={() => setShowSidebar(false)}
                            style={{ position: "absolute", right: "10px", top: "5px" }}  >
                            <IoClose size={20} />
                        </span>
                    </div>
                    {cart?.product.map((cData) => {
                        return (
                            <CartList
                                cData={cData}
                                key={cData.id}
                                setPayloadEdit={setPayloadEdit}
                                payloadEdit={payloadEdit}
                                // resetControls={resetControls}
                                setLoading={setLoading}
                            />
                        );
                    })}
                </div>
                {/* Place Order */}
                <div className="placeorder w-100 p-3" style={{ height: "24%" }}>
                    <OrderSummary cart={cart} />
                    <div className="placeOrderBtn w-100">
                        <button
                            className="btn btn-md w-100 btn-pills"
                            onClick={handlePlaceOrder}
                        >
                            Place Order
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Sidebar;