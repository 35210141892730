import React from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const Drink = ({ data }) => {
    const navigate = useNavigate();

    const handleNavigate = (code) => {
        navigate(`/customize-drink/${code}`);
    };

    return (
        <div className="m-card">
            <div
                className="grid-card-outer"
                key={"special-grid-card-" + data?.softdrinkCode}
            >
                <div className="grid-card-placeholder">
                    <div className="grid-top-container">
                        <img
                            src={data?.image}
                            alt={data?.softDrinksName}
                            className="bestseller-placeholder"
                        />
                        {/* <div className="grid-card-name">
                            <div className="grid-card-left">
                                <h5 className="mb-1">{data?.softDrinksName}</h5>
                            </div>
                        </div> */}
                    </div>
                    <div className="grid-card-detail">
                        <div className="pizzaTitleDiv">
                            <div className="pizzaTitle">
                                {/* <div className="square">
                                    <div className="circle"></div>
                                </div> */}
                                <h5 className="mb-1">{data?.softDrinksName}</h5>
                            </div>
                        </div>
                        <div className="grid-card-pizzasize text-start">
                            <span className="primaryWhiteColor fw-bolder">
                                $ {data.price}
                            </span>
                        </div>
                        <div className="btn-container">
                            <div className="grid-card-left"></div>
                            <div className="grid-card-right">
                                <span
                                    onClick={() => handleNavigate(data.softdrinkCode)}
                                    className="view-btn cursor-pointer"
                                >
                                    {/* <FaEdit /> */}
                                    Customize
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Drink;
