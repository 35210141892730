import React, { useRef, useEffect } from "react";
import Sides from "./Sides";
import pizzaLeft from "../../../assets/images/pizza-left.png";
import pizzaRight from "../../../assets/images/pizza-right.png";
import { A11y, Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function SideSlider({ data, cartFn }) {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            const swiperInstance = swiperRef.current.swiper;
            swiperInstance.params.navigation.prevEl = prevRef.current;
            swiperInstance.params.navigation.nextEl = nextRef.current;
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();
        }
    }, [data]); // Ensure this runs when data changes

    return (
        <div className="container">
            <div className="side-section-header text-start">
                <h3 className="side-section-title text-capitalize">
                    {/* <img src={pizzaLeft} alt="Left Pizza Icon" /> */}
                    &nbsp;
                    <span className="title-text">{data?.type}</span>
                    &nbsp;
                    {/* <img src={pizzaRight} alt="Right Pizza Icon" /> */}
                </h3>
            </div>

            <div className="position-relative">
                <div className="swiper-controls">
                    {/* Custom Prev and Next Buttons */}
                    <button
                        ref={prevRef}
                        className="side-swiper-button-prev side-custom-swiper-button"
                    >
                        <FaChevronLeft />
                    </button>
                    <button
                        ref={nextRef}
                        className="side-swiper-button-next side-custom-swiper-button"
                    >
                        <FaChevronRight />
                    </button>
                </div>
                <Swiper
                    ref={swiperRef}
                    modules={[Autoplay, Navigation, Pagination, A11y]}
                    loop={false}
                    slidesPerView={4.2}
                    spaceBetween={10}
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                    }}
                    pagination={{ clickable: true }}
                    className="px-1 py-2"
                >
                    {data?.sides?.map((side) => (
                        <SwiperSlide
                            key={side.sideCode}
                            className="p-0 rounded-bottom-5"
                        >
                            <Sides data={side} cartFn={cartFn} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default SideSlider;
