import React, { useContext, useEffect, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { getStoreByLatLong, getStoreLocationByCity } from '../../services';
import Select from "react-select";
import GlobalContext from '../../context/GlobalContext';
import { useLocation } from 'react-router-dom';

function LocationAccessContent({ currentTab, isModal, setShow }) {
    const location = useLocation();
    const [cities, setCities] = useState([]);
    const [stores, setStores] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);

    // Global Context
    const globalctx = useContext(GlobalContext);
    const [currentStoreCode, setCurrentStoreCode] = globalctx.currentStoreCode;
    const [currentCity, setCurrentCity] = globalctx.currentCity;
    const [currentStore, setCurrentStore] = globalctx.currentStore;
    const [scrollToSignature, setScrollToSignature] = globalctx.scrollToSignature;
    const [showStorePopup, setShowStorePopup] = globalctx.showStorePopup;
    const [currentLatitude, setCurrentLatitude] = globalctx.currentLatitude;
    const [currentLogitude, setCurrentLogitude] = globalctx.currentLogitude;

    // Fetch data from API
    const fetchData = async () => {
        try {
            const res = await getStoreLocationByCity();
            const cityOptions = res.data.map((item) => ({
                value: item.city,
                label: item.city,
                stores: item.storeLocations,
            }));
            setCities(cityOptions);
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const latLongWiseStore = async (lat, long) => {
        try {
            const res = await getStoreByLatLong(lat, long);

            if (res?.data?.length > 0) {
                const nearestStore = res.data.find((data) => data?.isNearestStore === 1);
                const code = nearestStore?.code || null;
                const city = cities.find((data) => data?.value === nearestStore?.city);

                setCurrentStoreCode(code);
                setSelectedCity(city);
                setCurrentCity(city);
                localStorage.setItem('currentCity', JSON.stringify(city));
                localStorage.setItem('currentStoreCode', code);

                const storeOptions = city?.stores.map((store) => ({
                    value: store.code,
                    label: store.storeLocation,
                }));

                const storeOption = storeOptions.find((store) => store.value === code);
                setStores(storeOptions);
                setCurrentStore(storeOption);
                setSelectedStore(storeOption);
                localStorage.setItem('currentStore', JSON.stringify(storeOption));
                // Set Lat Long
                setCurrentLatitude(lat);
                setCurrentLogitude(long);
                localStorage.setItem('currentLatitude', lat);
                localStorage.setItem('currentLogitude', long);
            } else {
                toast.warning(res.message);
            }
        } catch (error) {
            console.log(error);
            const errorMessage = 'An error occurred while fetching data.';
            toast.error(errorMessage);
        }
    };

    // Handle city selection
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setCurrentCity(selectedOption);
        localStorage.setItem('currentCity', JSON.stringify(selectedOption));

        setSelectedStore(null);
        setCurrentStore(null);
        setCurrentStoreCode(null);
        localStorage.removeItem('currentStoreCode');
        localStorage.removeItem('currentStore');

        const storeOptions = selectedOption?.stores?.map((store) => ({
            value: store.code,
            label: store.storeLocation,
        }));
        setStores(storeOptions);
        // reset lat long
        setCurrentLatitude(null);
        setCurrentLogitude(null);
        localStorage.setItem('currentLatitude', null);
        localStorage.setItem('currentLogitude', null);
    };

    // Handle store selection
    const handleStoreChange = (selectedOption) => {
        setCurrentStoreCode(selectedOption.value);
        setCurrentStore(selectedOption);
        localStorage.setItem('currentStoreCode', selectedOption.value);
        localStorage.setItem('currentStore', JSON.stringify(selectedOption));
        setSelectedStore(selectedOption);
        toast.success('Store selected successfully.');
        // reset lat long
        setCurrentLatitude(null);
        setCurrentLogitude(null);
        localStorage.setItem('currentLatitude', null);
        localStorage.setItem('currentLogitude', null);

        if (location.pathname === '/') {
            if (isModal) {
                setShow(false);
            }
            setScrollToSignature(true);
        }
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    // const latitude = "43.855930";
                    // const longitude = "-79.708390";
                    await latLongWiseStore(latitude, longitude);
                },
                (err) => {
                    const errorMessages = {
                        1: 'Permission denied. Please allow location access.',
                        2: 'Position unavailable. Ensure GPS or location services are enabled.',
                        3: 'Request timed out. Please try again.',
                    };
                    toast.warning(errorMessages[err.code] || 'An unknown error occurred.');
                }
            );
        } else {
            toast.error('Geolocation is not supported by your browser.');
        }
    };

    useEffect(() => {
        setSelectedCity(currentCity);
        setSelectedStore(currentStore);

        const storeOptions = currentCity?.stores?.map((store) => ({
            value: store.code,
            label: store.storeLocation,
        }));
        setStores(storeOptions);
    }, [currentCity, currentStore]);

    return (
        <div className='getLatLong'>
            <div className='w-100 text-left mb-3'>
                <p className='fs-4'>Let's Get Ordering</p>
                <p className='fs-4 fw-bold'>
                    {currentTab === 'delivery'
                        ? 'Select Your Nearest Stores'
                        : 'Pickup Items From Your Nearest Stores'}
                </p>
            </div>

            <div className='w-100 text-left z-3 mb-5'>
                <div className='row justify-content-lg-start justify-content-center align-items-center'>
                    <div className='col-xl-3 col-lg-4 col-md-12 col-sm-12 mb-3 mb-lg-0'>
                        <button
                            className='btn1 stl2 text-decoration-none fs-6 w-100'
                            onClick={getCurrentLocation}
                        >
                            <FaLocationDot className='mb-1' /> Use my current location
                        </button>
                    </div>

                    <div className='col-xl-1 col-lg-1 col-md-12 col-sm-12 text-center mb-3 mb-lg-0'>
                        <p className='fs-5'>OR</p>
                    </div>

                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3 mb-lg-0'>
                        <label htmlFor='city-select' className='form-label'>City:</label>
                        <Select
                            id='city-select'
                            options={cities}
                            value={selectedCity}
                            onChange={handleCityChange}
                            placeholder='Select a city...'
                            isSearchable
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                container: (base) => ({ ...base, width: '100%' }),
                            }}
                            aria-label='City Select'
                        />
                    </div>

                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3 mb-lg-0'>
                        <label htmlFor='store-select' className='form-label'>Store:</label>
                        <Select
                            id='store-select'
                            options={stores}
                            value={selectedStore}
                            onChange={handleStoreChange}
                            placeholder={
                                selectedCity ? 'Select a store...' : 'Select a city first...'
                            }
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                container: (base) => ({ ...base, width: '100%' }),
                            }}
                            isSearchable
                            isDisabled={!selectedCity}
                            aria-label='Store Select'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LocationAccessContent;
