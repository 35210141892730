import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingLayout from "../../../layouts/LoadingLayout";
import { customerResetPassword } from "../../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ValidateSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address")
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Invalid Email Address"
        )
        .required("Email is required"),
});

function ForgetPassword() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const onSubmit = async (values) => {
        setLoading(true);
        const payload = {
            email: values.email,
        };
        await customerResetPassword(payload)
            .then((res) => {
                toast.success(res?.message || 'OTP is sent to your email address"');
                navigate('/update-password/' + res?.data)
                setLoading(false);
            })
            .catch((err) => {
                if (err.response.status === 400 || err.response.status === 500) {
                    toast.error(err.response.data.message);
                }
                setLoading(false);
            });
    };
    // Use Formik
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validateOnBlur: true,
        validationSchema: ValidateSchema,
        onSubmit,
        enableReinitialize: true,
    });

    useEffect(() => {
        const user = localStorage.getItem("user") ?? null;
        if (user != null) {
            const userData = JSON.parse(user);
            if (userData) {
                navigate("/");
            }
        }
        setLoading(false);
    }, [navigate]);

    if (loading) return <LoadingLayout />;
    return (
        <>
            <Header />
            <div className="nav-margin"></div>
            <div
                className="container-fluid d-flex justify-content-center align-items-center"
                style={{ minHeight: "calc(100vh - 400px)" }}
            >
                <div className="container row py-md-2 py-4 px-0 m-0" >
                    <div className="col-12 ">
                        <div className="row gx-3 justify-content-center align-content-center py-3">
                            <div className="content card-background-color col-lg-5 col-md-6 col-sm-12 rounded px-md-4 px-3 py-md-4 py-3">
                                <h3 className="mb-4">
                                    <strong>Forget Password</strong>
                                </h3>
                                <form className="w-100" onSubmit={formik.handleSubmit}>
                                    <div className="row gx-3">
                                        {/* Email */}
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <label className="form-label">
                                                Email <small className="text-danger">*</small>
                                            </label>
                                            <input
                                                className=" form-control mb-3"
                                                type="email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger formErrMsg mt-2 mb-3">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="w-100 text-center mb-3 mt-2">
                                        <button
                                            type="submit"
                                            className="w-100 py-2 fw-bold btn btn-md rounded-5 text-uppercase loginBtn"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ForgetPassword;
