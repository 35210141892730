import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";


function SpecialOffers({ data }) {
    const handleLinkClick = (e) => {
        if (e.target.tagName === "SELECT" || e.target.tagName === "OPTION") {
            e.preventDefault();
        }
    };

    return (
        <div className="m-card">
            <div className="grid-card-outer" key={"special-grid-card-" + data?.code}>
                <Link to={`${"/specialoffer"}/${data?.code}`} onClick={handleLinkClick}>
                    <div className="grid-card-placeholder">
                        <div className="grid-top-container">
                            <img
                                src={data?.image}
                                alt={data?.name}
                                className="bestseller-placeholder"

                            />
                            {data?.dealType !== "otherdeal" && (
                                <div className={`top-pizza-toggle ${data?.dealType}`}>
                                    <div className={`tag_list`}>
                                        <span>{data?.dealType === "pickupdeal" ? "Pickup Deal" : "Delivery Deal"}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="grid-card-detail">
                            <div className="pizzaTitleDiv">
                                <div className="pizzaTitle">
                                    <h5 className="mb-1 text-decoration-none">
                                        {data?.name}
                                    </h5>
                                </div>
                            </div>
                            <div className="pizzaSubTitleDiv">
                                <div className="pizzaSubTitle">
                                    <p className="mb-1 text-decoration-none">
                                        {data?.subtitle ? data.subtitle : <>&nbsp;</>}
                                    </p>
                                </div>
                            </div>
                            <div className="grid-card-pizzasize">
                                <select className="select-input w-100 pizzaPriceSelect">
                                    {data?.pizza_prices
                                        ?.filter((price) => parseFloat(price.price) > 0)
                                        ?.map((price) => (
                                            <option value={price.shortcode} key={price.shortcode}>
                                                {price.size} - $ {price.price}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="btn-container">
                                <div className="grid-card-left"></div>
                                <div className="grid-card-right">
                                    <span className="view-btn">
                                        {/* <FaEdit /> */}
                                        Customize
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default SpecialOffers;
