import React from 'react'
import '../assets/styles/termcondtion.css'
import Footer from '../components/_main/Footer'
import Header from '../components/_main/Header'

function TermsCondtions() {
    return (
        <>
            <Header />
            <div className="new-block my-5">
                <div className="nav-margin"></div>
                <div className='container'>
                    <div className='para mb-5'>
                        <p className='fs-2 fw-bold paraTitle'>Terms and Conditions </p>
                    </div>
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle'></p>
                        <p className='fs-6 text'>
                            Welcome to <strong>Mr. Singh’s Pizza</strong> ("we," "our," or "us"). These Terms and Conditions ("Terms") govern your use of our website, online ordering platform, telecalling services, and in-store offerings. By accessing or using our services, you agree to be bound by these Terms. If you do not agree, please refrain from using our services.
                        </p>
                    </div>
                    {/* 1 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>1. Use of Services</p>
                        <p className='fs-6 text'>
                            You may use our services for personal, non-commercial purposes in compliance with these Terms. By placing an order, you represent that:
                        </p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>You are at least 18 years of age or have parental consent to use our services.</p></li>
                            <li><p className='fs-6 text'>All information you provide is accurate, complete, and up-to-date.</p></li>
                        </ul>
                    </div>
                    {/* 2 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>2. Ordering and Payment</p>
                        <p className='fs-6 fw-bold text paraSubTitle'>a. Order Placement:</p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>Orders can be placed online, for pickup, or through telecalling.</p></li>
                            <li><p className='fs-6 text'>All orders are subject to availability and confirmation.</p></li>
                        </ul>
                        <p className='fs-6 fw-bold text paraSubTitle'>b. Payment:</p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>Payments can be made through our secure payment gateways or in-store.</p></li>
                            <li><p className='fs-6 text'>We accept major credit cards, debit cards, and other available payment methods.</p></li>
                            <li><p className='fs-6 text'>Prices are listed in CAD and include applicable taxes.</p></li>
                        </ul>
                        <p className='fs-6 fw-bold text paraSubTitle'>c. Order Confirmation:</p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>You will receive an order confirmation via email or SMS. If you do not receive confirmation, contact us immediately at 9055004000.</p></li>
                        </ul>
                        <p className='fs-6 fw-bold text paraSubTitle'>d. Cancellations and Modifications:</p>
                        <ul className='mx-5'>
                            <li><p className='fs-6 text'>Cancellations or modifications are allowed only if the order has not been prepared. Contact us as soon as possible to request changes.</p></li>
                        </ul>
                    </div>
                    {/* 3 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>3. Pickup and Delivery</p>
                        <p className='fs-6 fw-bold text paraSubTitle'>a. Pickup Orders:</p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>Orders must be picked up at the specified time and location. Proof of purchase may be required.</p>
                            </li>
                        </ul>
                        <p className='fs-6 fw-bold text paraSubTitle'>b. Delivery:</p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>Delivery is subject to availability and limited to certain areas.</p>
                            </li>
                            <li>
                                <p className='fs-6 text'>Ensure the provided delivery address is accurate. We are not responsible for delays or issues due to incorrect addresses.</p>
                            </li>
                        </ul>
                    </div>
                    {/* 4 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>4. Refunds and Returns</p>
                        <p className='fs-6 text'>
                            We strive to provide the best pizza experience. If you are dissatisfied with your order, contact us at
                            <span className="fw-bold"> 9055004000 </span> or
                            <span className="fw-bold"> Mrsinghpizza@hotmail.com.</span>
                            Refunds or replacements may be issued at our discretion for valid reasons, such as incorrect or damaged orders.
                        </p>
                    </div>
                    {/* 5 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>5. User Responsibilities</p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>Use our services lawfully and responsibly.</p>
                            </li>
                            <li>
                                <p className='fs-6 text'>Refrain from reselling or misusing our products.</p>
                            </li>
                            <li>
                                <p className='fs-6 text'>Protect your account credentials if creating an online account.</p>
                            </li>
                        </ul>
                    </div>
                    {/* 6 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>6. Intellectual Property</p>
                        <p className='fs-6 text'>
                            All content on our website and materials related to our services, including logos, images, and text, are the property of <strong>Mr. Singh’s Pizza</strong>. You may not reproduce, distribute, or use any content without prior written permission.
                        </p>
                    </div>
                    {/* 7 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>7. Limitation of Liability</p>
                        <p className='fs-6 text'>To the fullest extent permitted by law, <strong>Mr. Singh’s Pizza</strong> is not liable for any:</p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>
                                    Indirect, incidental, or consequential damages arising from your use of our services.
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    Delays or interruptions caused by third-party services, such as payment processors or delivery partners.
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* 8 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>8. Modifications to Terms</p>
                        <p className='fs-6 text'>
                            We may update these Terms from time to time. The updated Terms will be posted on our website with the new effective date. Continued use of our services constitutes acceptance of the updated Terms.
                        </p>
                    </div>
                    {/* 9 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>9. Governing Law</p>
                        <p className='fs-6 text'>
                            These Terms are governed by the laws of the Province of Ontario, Canada. Any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts in Ontario.
                        </p>
                    </div>
                    {/* 10 */}
                    <div className='para mb-5'>
                        <p className='fs-5 fw-bold paraTitle mb-2'>10. Contact Us</p>
                        <p className='fs-6 text'>
                            If you have any questions or concerns about this Privacy Policy or how your information is handled, please contact us:
                        </p>
                        <ul className='mx-5'>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Email:</strong> <a className='text-decoration-none' href="mailto:Mrsinghpizza@hotmail.com">Mrsinghpizza@hotmail.com</a>
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Phone:</strong> 9055004000
                                </p>
                            </li>
                            <li>
                                <p className='fs-6 text'>
                                    <strong>Address:</strong> 2130 North Park Drive, Brampton, ON, CA L6S 5M4
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default TermsCondtions