import React, { useState } from 'react'
import SpecialCheese from './Configurations/Cheese';
import SpecialSpicy from './Configurations/Spicy';
import SpecialSauce from './Configurations/Sauce';
import Toppings from './Configurations/Toppings';
import SpecialDough from './Configurations/Dough';
import SpecialCook from './Configurations/Dough/Cook';

function SpecialPizzaConfig({ count, specialOfferData, pizzaState, setPizzaState, toppingsData, activeAccordion, toggleAccordion }) {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="mt-3 ">
            <div className="accordion muti-pizza-accordian" id={`accordionExample${count}`}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={`fw-bold fs-6 accordianBtn accordion-button ${isOpen ? '' : 'collapsed'}`}
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                            aria-expanded={isOpen ? 'true' : 'false'}
                            aria-controls="collapseOne"
                        >
                            PIZZA - {count + 1}
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                        aria-labelledby="headingOne"
                        data-bs-parent={`#accordionExample${count}`}
                        style={{ overflow: "hidden", padding: "15px 0px" }}
                    >
                        <div className="accordion-body primary-background-color">
                            <SpecialDough count={count} pizzaState={pizzaState} setPizzaState={setPizzaState} specialOfferData={specialOfferData} activeAccordion={activeAccordion} toggleAccordion={toggleAccordion} />
                            <SpecialCheese count={count} pizzaState={pizzaState} setPizzaState={setPizzaState} specialOfferData={specialOfferData} activeAccordion={activeAccordion} toggleAccordion={toggleAccordion} />
                            <SpecialSpicy count={count} pizzaState={pizzaState} setPizzaState={setPizzaState} specialOfferData={specialOfferData} activeAccordion={activeAccordion} toggleAccordion={toggleAccordion} />
                            <SpecialSauce count={count} pizzaState={pizzaState} setPizzaState={setPizzaState} specialOfferData={specialOfferData} activeAccordion={activeAccordion} toggleAccordion={toggleAccordion} />
                            <SpecialCook count={count} pizzaState={pizzaState} setPizzaState={setPizzaState} specialOfferData={specialOfferData} activeAccordion={activeAccordion} toggleAccordion={toggleAccordion} />
                            <Toppings count={count} pizzaState={pizzaState} setPizzaState={setPizzaState} toppingsData={toppingsData} activeAccordion={activeAccordion} toggleAccordion={toggleAccordion} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecialPizzaConfig